import React, { Component } from "react";
import PropTypes from "prop-types";
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { connect } from "react-redux";
import { EXPAND_LEFT } from "react-ladda";
import { connectModal } from "redux-modal";
import { AvFeedback, AvField, AvForm, AvGroup } from "availity-reactstrap-validation";

import { deleteMyAccountRequest } from "../redux/actions/my-profile";
import { Separator, SubmitButton } from "./styled-components";

class ModalDeleteMyAccount extends Component {

	static propTypes = {
		handleHide: PropTypes.func.isRequired,
		show: PropTypes.bool.isRequired
	};

	formRef = React.createRef();

	submit = () => {
		this.formRef.current.submit();
	};

	handleValidSubmit = (event, values) => {
		this.props.deleteMyAccountRequest(values.email);
	};

	render() {

		const { show, handleHide } = this.props;

		return (
			<Modal
				isOpen={show}
				fade={true}
				toggle={handleHide}
				size={"lg"}
				backdrop={true}>

				<ModalHeader>Delete My Account</ModalHeader>

				<ModalBody>

					<p>
						Your account will be <strong>archived</strong>. <br/>
						Active connection and running test will be closed.
					</p>

					<p>
						<strong>In 14 days</strong> from now your account will be <strong>completely removed</strong>
						and any information will be lost.
					</p>

					<Alert color={"info"}>
						Log in again during the next 14 days and we will
						activate your account and cancel the deletion.
					</Alert>

					<Separator className={"mt-4 mb-4 pl-2 pr-2"} />

					<p>
						Deletion of your account is possible only if the following terms are met:
						<ul>
							<li>
								Non paying account
							</li>
							<li>
								No additional users on your team
							</li>
						</ul>
					</p>

					<Separator className={"mt-4 mb-4 pl-2 pr-2"} />

					<AvForm ref={this.formRef} onValidSubmit={this.handleValidSubmit}>
						<AvGroup>
							<AvField
								type="email"
								name="email"
								label="Enter your email to confirm the deletion"
								required
							/>
							<AvFeedback></AvFeedback>
						</AvGroup>
					</AvForm>

					{this.props.error && <Alert color={"danger"}>{this.props.error.message}</Alert>}

				</ModalBody>

				<ModalFooter>
					<Button color="secondary" onClick={handleHide}>
						Close
					</Button>
					<SubmitButton
						onClick={this.submit}
						loading={this.props.sending ? 1 : 0}
						data-color="#eee"
						data-style={EXPAND_LEFT}
						data-spinner-color="#ddd">
						Confirm
					</SubmitButton>
				</ModalFooter>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => ({
	error: state.deleteMyAccount.error,
	sending: state.deleteMyAccount.sending
});

const mapDispatchToProps = (dispatch) => ({
	deleteMyAccountRequest: (email) => dispatch(deleteMyAccountRequest(email))
});

const WrappedModalDeleteMyAccount = connect(
	mapStateToProps,
	mapDispatchToProps
)(ModalDeleteMyAccount);

export default connectModal({ name: "deleteMyAccount" })(WrappedModalDeleteMyAccount);
