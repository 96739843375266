import {
	GET_PROFILE_CANCELLED,
	GET_PROFILE_FULFILLED,
	GET_PROFILE_PENDING,
	GET_PROFILE_REJECTED
} from "../actions/my-profile";
import { RESET_REQUEST } from "../actions/authentication";

const initialState = {
	isLoading: false,
	content: null,
	error: null
};

const myProfileReducer = (state = initialState, action) => {
	switch (action.type) {

		case GET_PROFILE_PENDING:
			return {
				...state,
				isLoading: true
			};

		case GET_PROFILE_FULFILLED:
			return {
				...state,
				content: action.payload,
				isLoading: false
			};

		case GET_PROFILE_REJECTED:
			return {
				...state,
				error: action.payload,
				isLoading: false
			};

		case GET_PROFILE_CANCELLED:
			return {
				...state,
				isLoading: false
			};

		case RESET_REQUEST:
			return initialState;

		default:
			return state;
	}
};

export const isPayingUser = (state) => (
	state &&
	state.content &&
	state.content.currentPlan &&
	state.content.currentPlan !== "free" &&
	state.content.currentPlan !== "trial"
);

export default myProfileReducer;
