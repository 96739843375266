import { Col, Row } from "reactstrap";
import React, { Component } from "react";

import SmallHomeLink from "../../components/small-home-link";
import LoginLayout from "../../components/layout-login";

class EmailUnverified extends Component {
	render() {
		return (
			<LoginLayout>
				<Row>
					<Col className="d-flex justify-content-between align-items-center">
						<div className="m-3">
							<div className="show-md text-left">
								<SmallHomeLink/>
							</div>
						</div>
					</Col>
				</Row>

				<Row className={"flex-1"}>
					<Col className="d-flex flex-column justify-content-center align-items-center">
						<div className={"d-flex flex-column align-items-center content-section"}>
							<h1>Thank you for signing up!</h1>

							<p className="lead">
								Please activate your account by clicking the verification link in the email we sent
								you.{" "}
								<br/>
								<br/>
							</p>
							<div className="d-flex flex-column justify-content-center align-items-center">
								<img src="/whole_envlp.png" alt="activate your account"/>
							</div>

							<p>
								<br/>
								If you didn't get the verification email, please contact {" "}
								<a rel="noreferrer noopener" target="_blank" href="mailto:support@experitest.com">support@experitest.com</a>
							</p>
						</div>
					</Col>
				</Row>
			</LoginLayout>
		);
	}
}

export default EmailUnverified;
