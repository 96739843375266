import { SHOW } from "redux-modal/lib/actionTypes";
import { apiRequest, ignoreApi } from "../actions/api";
import {
	DELETE_MY_ACCOUNT_FULFILLED,
	DELETE_MY_ACCOUNT_REJECTED,
	DELETE_MY_ACCOUNT_REQUEST,
	GET_PROFILE_CANCELLED,
	GET_PROFILE_FULFILLED,
	GET_PROFILE_PENDING,
	GET_PROFILE_REJECTED,
	loadMyProfile,
	openDeleteMyAccountModal,
	UPDATE_PROFILE_FULFILLED,
	UPDATE_PROFILE_REJECTED,
	UPDATE_PROFILE_REQUEST
} from "../actions/my-profile";
import { logout } from "../actions/authentication";
import { hideSpinner, showSpinner } from "../actions/ui";
import { resetFormMessage, setFormMessage } from "../actions/forms";


const getMyProfileFlow = ({ dispatch, getState }) => next => action => {
	next(action);


	if (action.type === GET_PROFILE_PENDING) {

		let shouldUpdate = false;

		if (getState().myProfile.content === null) {
			shouldUpdate = true;
		}

		if (action.meta && action.meta.ignoreCache) {
			shouldUpdate = true;
		}

		if (shouldUpdate) {
			dispatch(apiRequest("GET", "/api/users/me", null, GET_PROFILE_FULFILLED, GET_PROFILE_REJECTED));
		} else {
			dispatch(ignoreApi("GET", "/api/users/me"));
			dispatch({ type: GET_PROFILE_CANCELLED });
		}
	}
};

const deleteMyAccountFlow = ({ dispatch }) => next => action => {
	next(action);

	if (action.type === SHOW &&
		action.payload.modal === "deleteMyAccount") {
		dispatch(openDeleteMyAccountModal());
	}

	if (action.type === DELETE_MY_ACCOUNT_REQUEST) {
		dispatch(apiRequest("DELETE", "/api/users/me", action.payload, DELETE_MY_ACCOUNT_FULFILLED, DELETE_MY_ACCOUNT_REJECTED));
	}

	if (action.type === DELETE_MY_ACCOUNT_FULFILLED) {
		dispatch(logout("/delete-account-success"));
	}

};

const updateMyProfileFlow = ({ dispatch }) => next => action => {
	next(action);

	if (action.type === UPDATE_PROFILE_REQUEST) {
		dispatch(showSpinner());
		dispatch(resetFormMessage("userProfile"));
		dispatch(apiRequest("PUT", "/api/users/me", action.payload, UPDATE_PROFILE_FULFILLED, UPDATE_PROFILE_REJECTED));
	}

	if (action.type === UPDATE_PROFILE_FULFILLED) {
		dispatch(hideSpinner());
		dispatch(setFormMessage("userProfile", "Success", false));
		dispatch(loadMyProfile(true));
	}

	if (action.type === UPDATE_PROFILE_REJECTED) {
		dispatch(hideSpinner());
		dispatch(setFormMessage("userProfile", action.payload.message, true));
	}

};

export default [getMyProfileFlow, deleteMyAccountFlow, updateMyProfileFlow];
