import { Container } from "reactstrap";
import { NavLink } from "react-router-dom";
import styled from "styled-components";


export const Logo = styled.div`
    background: url(https://experitest.s3.amazonaws.com/website/white-logo.png) no-repeat;
    height: 41px;
    width: 135px;
    background-size: contain;
    background-position: center;
    border: none;
    cursor:pointer;
`;

export const Wrapper = styled.div`
    background: url(/home-banner.png) no-repeat center;
    background-size: cover;
    color: white;
    box-shadow: 0 2px 5px 0 rgba(100,100,100,0.2);
`;

export const UserRow = styled(Container)`
    padding: 20px 0;
`;

export const Link = styled(NavLink)`

    background-color: transparent;
    color: grey;
    display: flex;
    align-items: center;
    padding: 10px 35px;

    &:hover {
        color: white;
    }
    
    &.active {
        color: white;
        background-color: transparent;
        border-bottom: 3px solid orange;
    }
`;
export const LinkCart = styled(NavLink)`

    background-color: transparent;
    color: #dadada;
    display: flex;
    align-items: center;
    padding: 10px 35px;

    &:hover {
        color: white;
    }
    
    &.active {
        color: white;
        background-color: transparent;
        border-bottom: 3px solid orange;
    }
`;
