import {REACT_APP_CLOUD_URL} from "../environment";

export const PASSWORD_PATTERN = new RegExp("^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?!.*[^A-Za-z0-9]).*$");

/**
 * NAME PATTERN
 * only a-z A-z and spaces are allowed
 */
export const NAME_PATTERN = new RegExp("^[a-zA-Z]+[a-zA-Z ]*$");

/**
 * List of connection provider as listed in oauth0
 * @type {{GOOGLE: string, GITHUB: string, BITBUCKET: string}}
 */
export const LOGIN_PROVIDER = {
    GOOGLE: "google-oauth2",
    GITHUB: "github",
    GSUITE: "experitest-com",
    BITBUCKET: "bitbucket",
	LINKEDIN: "linkedin"
};

export const REDIRECT_PAGES = {
    CLOUD_LAUNCHPAD: {
        uri: `${REACT_APP_CLOUD_URL}/index.html#/devices`
    },
	CONTACT_US_LANDING_PAGE : {
    	uri: 'https://digital.ai/contact'
	}
};
