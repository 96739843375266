import { HIDE_SPINNER, SHOW_SPINNER } from "../actions/ui";

const initialState = {
	loading: false
};

const reducer = (state = initialState, action) => {

	switch (action.type) {
		case SHOW_SPINNER:
			return {
				...state,
				loading: true
			};

		case HIDE_SPINNER:
			return {
				...state,
				loading: false
			};

		default:
			return state;
	}
};

export default reducer;
