import { GET_TEAM_SUB_CANCELLED,GET_TEAM_SUB_FULFILLED,GET_TEAM_SUB_PENDING, GET_TEAM_SUB_REJECTED} from "../actions/team-subscription";
import { RESET_REQUEST } from "../actions/authentication";

const initialState = {
	isLoading: false,
	content: null,
	error: null
};

const reducer = (state = initialState, action) => {

	switch (action.type) {

		case GET_TEAM_SUB_PENDING:
			return {
				...state,
				isLoading: true
			};

		case GET_TEAM_SUB_FULFILLED:
			return {
				...state,
				content: action.payload,
				isLoading: false
			};

		case GET_TEAM_SUB_REJECTED:
			return {
				...state,
				error: action.payload,
				isLoading: false
			};

		case GET_TEAM_SUB_CANCELLED:
			return {
				...state,
				isLoading: false
			};

		case RESET_REQUEST:
			return initialState;

		default:
			return state;
	}
};

export const willPayInNextPlan = (state) => {
	return (
		state &&
		state.content &&
		state.content.nextChargePlanName &&
		!(
			state.content.nextChargePlanName.toLowerCase() === "free" ||
			state.content.nextChargePlanName.toLowerCase() === "trial"
		)
	)
};

export default reducer;
