import React, { Component } from "react";
import { Alert, Button, Col, FormText, Row } from "reactstrap";
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";
import { Link } from "react-router-dom";
import { EXPAND_LEFT } from "react-ladda";
import * as queryString from "query-string";
import { connect } from "react-redux";

import LoginLayout from "../../components/layout-login";
import { LOGIN_PROVIDER } from "../../utils/constants";
import {
	LinkedinButton,
	GoogleButton,
	GSuiteButton
} from "../../components/social-buttons";
import OrDivider from "../../components/or-divider";
import SmallHomeLink from "../../components/small-home-link";
import { PageLayover, SubmitButton } from "../../components/styled-components";
import {
	loginWithConnection,
	loginWithCredentials
} from "../../redux/actions/authentication";

class Login extends Component {

	state = {
		experitest: false
	};

	isIe11() {
		return !!navigator.userAgent.match(/Trident.*rv[ :]*11\./);
	}

	componentWillMount() {

		const { source, medium, campaign, term } = queryString.parse(this.props.router.location.search);
		source && localStorage.setItem("source", source);
		medium && localStorage.setItem("medium", medium);
		campaign &&	localStorage.setItem("campaign", campaign);
		term && localStorage.setItem("term", term);

		const referrer = document.referrer || "";
		localStorage.setItem("referrer", referrer);
		console.log(document.referrer || "no referrer");

		if (source === "experitest") {
			this.setState({ experitest: true });
		}
	}

	handleValidSubmit = (event, values) => {
		const { email, password } = values;
		this.props.loginWithCredentials(email, password);
	};

	render() {

		return (
			<LoginLayout>

				<PageLayover active={this.props.loading}/>
				{!this.isIe11() ? (
					<Row>
						<Col
							className="d-flex justify-content-between align-items-center">
							<div className="m-3">
								<div className="show-md text-left">
									<SmallHomeLink/>
								</div>
							</div>

							<p className="text-right text-help m-3">
							<span className={"hide-sm"}>
								Don't have an account yet?
							</span>
								{!this.state.experitest ? (
									<Link to="/signup">
										<Button color={"primary"}
												className="ml-3 text-help">
											Signup
										</Button>
									</Link>
								) : (
									<a href="https://experitest.com/free-trial">
										<Button color={"primary"}
												className="ml-3 text-help">
											Signup
										</Button>
									</a>
								)}
							</p>
						</Col>
					</Row>
				) : (
					<Row className="ie11">
						<div>
							<h2 className="text-left">Unfortunately, we do not
								support IE Browser :(</h2>
							<p className="lead text-left mb-5">Please open the
								site in a supported browser: Chrome, Edge,
								Firefox, Safari.<br/>


								You can contact to <a
									href="mailto:support@experitest.com"
								>support@experitest.com </a>for
								additional info</p>

						</div>
					</Row>
				)

				}


				{!this.isIe11() ? (

					<Row>
						<div className="content-section">

							<h2 className="text-left"> WELCOME BACK!</h2>
							<p className="lead text-left mb-5">Log in to your
								account and resume testing</p>

							{!this.state.experitest && (
								<Row>
									<div className="mb-2 col-4">
										<GoogleButton
											disabled={this.props.loading}
											onClick={() => this.props.loginWithConnection(
												LOGIN_PROVIDER.GOOGLE)}
										/>
									</div>

									<div className="mb-2 col-4">
										<LinkedinButton
											disabled={this.props.loading}
											onClick={() => this.props.loginWithConnection(
												LOGIN_PROVIDER.LINKEDIN)}
										/>
									</div>

									{false && <div className="mb-2 col-4">
										<GSuiteButton
											disabled={this.props.loading}
											onClick={() => this.props.loginWithConnection(
												LOGIN_PROVIDER.GSUITE)}
										/>
									</div>
									}

									<div className="content-section">
										<OrDivider/>
									</div>
								</Row>
							)}

							<AvForm
								className="text-left"
								onValidSubmit={(event,
									values) => this.handleValidSubmit(event,
									values)}>
								<AvField
									name="email"
									label="COMPANY EMAIL"
									type="email"
									placeholder="your@email.com"
									disabled={this.props.loading}
									validate={{
										required: {
											value: true,
											errorMessage: "Please enter your email address"
										},
										email: {
											value: true,
											errorMessage: "Email is invalid"
										}
									}}
								/>

								<AvField
									name="password"
									label="PASSWORD"
									className={"m-0"}
									type="password"
									disabled={this.props.loading}
									placeholder="Enter your password"
									validate={{
										required: {
											value: true,
											errorMessage: "Please enter your password"
										},
										minLength: {
											value: 8,
											errorMessage: "Password is too short"
										}
									}}
								/>

								<FormText className="text-right m-0">
									<Link to="/forgot-password"
										  tabIndex="-1"
										  className="forgot-password">
										Forgot Password?
									</Link>
								</FormText>

								{this.props.error &&
								<Alert
									color="danger">{this.props.error}</Alert>}

								<AvGroup className="text-center">
									<SubmitButton
										loading={this.props.loading ? 1 : 0}
										data-style={EXPAND_LEFT}
										width={150}>
										Login
									</SubmitButton>
								</AvGroup>
							</AvForm>
						</div>
					</Row>
				) : ""
				}
				<Row/>

			</LoginLayout>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	error: state.login.error,
	loading: state.login.loading,
	router: state.router
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	loginWithCredentials: (email, password) => dispatch(
		loginWithCredentials(email, password)),
	loginWithConnection: (connection) => dispatch(
		loginWithConnection(connection))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Login);
