import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {MyTeamActions} from "../utils/my-team";

class ModalDeleteInvitation extends Component {

    static propTypes = {
        closeModal: PropTypes.func,
        email: PropTypes.string.isRequired
    };

    state = {
        error: null
    };

    close = () => {
        this.props.closeModal();
    };

    confirm = () => {

        this.setState({ error: null });

		MyTeamActions.deleteEmailInvitation(this.props.email)
            .then(() => {
                this.close();
            })
            .catch((err) => {
                console.log(err);
				this.setState({ error: err.response.data.message });
            });
    };

    render() {
        return (
            <Modal
                autoFocus={true}
                isOpen={this.props.isOpen}
                fade={true}
                toggle={this.close}
                backdrop={true}>

                <ModalHeader>Delete Invitation</ModalHeader>

                <ModalBody>
                    Are you sure you wish to delete the invitation to the <strong>{this.props.email}</strong>?
                    {this.state.error && <Alert color={"danger"}>{this.state.error}</Alert>}
                </ModalBody>

                <ModalFooter>
                    <Button color="secondary" onClick={this.close}>
                        Close
                    </Button>
                    <Button color="primary" onClick={this.confirm}>
                        Confirm
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default ModalDeleteInvitation;
