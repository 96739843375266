import React from "react";
import { Col, Container, Nav, NavItem, Row, Navbar } from "reactstrap";
import UserMenu from "./user-menu";
import { Link,LinkCart, Logo, UserRow, Wrapper } from "./accounts-header.styled";
import { Icon } from "./icon";
import { connect } from "react-redux";

const Header = ({ user, idToken }) => (
	<Wrapper>
		<UserRow className={"d-flex justify-content-between"}>
			<Col>
				<a href={"/redirect-cloud"}> <Logo/></a>
			</Col>
			<Col>
				<Row className={"justify-content-end"}>
					<LinkCart to="/upgrade/plans" className={"p-3"} target={"_blank"}>
						<Icon name={"shopping-cart"}/>
						<span className={"ml-2"}>Upgrade Now</span>
					</LinkCart>
					<UserMenu user={user} picture={idToken.picture}/>
				</Row>
			</Col>
		</UserRow>

		<Container>
			<Navbar className="no-padding">


				<Nav className="mr-auto">
					<NavItem>
						<Link className="nav-link" to="/account">
							Account
						</Link>
					</NavItem>
					<NavItem>
						<Link className="nav-link" to="/billing">
							Billing
						</Link>
					</NavItem>
					<NavItem>
						<Link className="nav-link" to="/team">
							My Team
						</Link>
					</NavItem>
				</Nav>
				<a className="white-link" href={"/redirect-cloud"}>Back to Cloud</a>
			</Navbar>

		</Container>
	</Wrapper>
);

const mapStateToProps = (state, ownProps) => ({
	user: state.myProfile.content || {},
	idToken: state.authentication.idToken
});

export default connect(
	mapStateToProps
)(Header);

