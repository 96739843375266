import React from "react";
import { ListGroup, ListGroupItem } from "reactstrap";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import LayoutFullPage from "./layout-full-page";


const SideMenu = styled.div`
	//padding: 20px;
	padding: 20px 0;
	//width: 280px;
    font-size: 14px;

	.list-group-item {
		
		a {
			color: orange;
		}
	
		&.active {
			background-color: orange;
			border-color: orange;
			
			a {
				color: white;
			}
		}
	}
		
`;

const Menu = ({ router }) => (
	<SideMenu>
		<ListGroup flush>
			<ListGroupItem tag={Link} to={"/billing/subscription"}
						   active={router.location.pathname === "/billing/subscription"} action>
				Subscription
			</ListGroupItem>
			<ListGroupItem tag={Link} to={"/billing/usage"} active={router.location.pathname === "/billing/usage"}
						   action>
				Cloud Usage
			</ListGroupItem>
			<ListGroupItem tag={Link} to={"/billing/history"} active={router.location.pathname === "/billing/history"}
						   action>
				Billing History
			</ListGroupItem>
		</ListGroup>
	</SideMenu>
);

const mapStateToProps = state => ({
	router: state.router
});

const ConnectedMenu = connect(
	mapStateToProps
)(Menu);

const LayoutBillingPage = ({ children }) => (
	<LayoutFullPage Menu={ConnectedMenu}>
		{children}
	</LayoutFullPage>
);

export default LayoutBillingPage;
