import React, { Component } from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";

import { loadMyProfile } from "../redux/actions/my-profile";
import * as queryString from "query-string";

class PrivateRoute extends Component {

	static propTypes = {
		component: (props, propName, componentName) => {
			if (!props.component && !props.render) {
				return new Error(`One of props 'data' or 'url' was not specified in '${componentName}'.`);
			}
		},
		render: (props, propName, componentName) => {
			if (!props.component && !props.render) {
				return new Error(`One of props 'url' or 'data' was not specified in '${componentName}'.`);
			}
		}
	};

	componentWillMount() {

		const { source, medium, campaign, term } = queryString.parse(this.props.router.location.search);
		source && localStorage.setItem("source", source);
		medium && localStorage.setItem("medium", medium);
		campaign &&	localStorage.setItem("campaign", campaign);
		term && localStorage.setItem("term", term);

		const referrer = document.referrer || "";
		console.log(document.referrer || "no referrer");
		localStorage.setItem("referrer", referrer);
	}


	componentDidMount() {
		this.props.loadMyProfile();
	}

	render() {

		if (!this.props.user) {
			return <div/>;
		}

		const smsVerificationRequired = this.props.auth.isSmsVerificationRequired();

		if (smsVerificationRequired) {
			return (
				<Redirect
					to={{
						pathname: "/sms-verification",
						state: { from: this.props.location }
					}}
				/>
			);
		}

		const authenticated = this.props.auth.isAuthenticated();


		if (!authenticated) {

			return (
				<Redirect
					to={{
						pathname: "/",
						state: { from: this.props.location }
					}}
				/>
			);
		}



		const { component: WrappedComponent, render: ComponentRenderFn, ...rest } = this.props;

		if (ComponentRenderFn) {

			return (
				<Route
					{...rest}
					render={ComponentRenderFn}
				/>
			);

		}


		return (
			<Route
				{...rest}
				render={props => <WrappedComponent idToken={this.props.idToken} {...props} />}
			/>
		);


	}
}

const mapStateToProps = (state, ownProps) => ({
	user: state.myProfile.content,
	router: state.router,
	idToken: state.authentication.idToken,
	auth: state.authentication.auth
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	loadMyProfile: () => dispatch(loadMyProfile())
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PrivateRoute);

