import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { DropdownItem, Nav, Row, UncontrolledDropdown } from "reactstrap";

import UserAvatar from "./user-avatar";
import { FullName, Menu, MenuCollapseButton, MenuItem, ViewProfileButton } from "./user-menu.styled";
import { logout } from "../redux/actions/authentication";


const UserMenu = ({ user, picture, navigate, auth, logout, showViewProfileLink, showOpenCloudLink, showUpgradePlanLink, dark}) => (
	<Nav navbar>
		<UncontrolledDropdown nav>
			<MenuCollapseButton nav caret dark={dark}>
				<UserAvatar picture={picture} size={30}/>{" "}
				<FullName>{`${user.firstName} ${user.lastName}`}</FullName>
			</MenuCollapseButton>
			<Menu right>
				<DropdownItem header tag={"div"} toggle={false}>
					<Row className={"d-flex align-items-center"}>
						<UserAvatar
							picture={picture}
							size={55}
							round={false}
							frame={false}
						/>
						<div>
							<h6>{`${user.firstName} ${user.lastName}`}</h6>
							{showViewProfileLink &&
							<ViewProfileButton onClick={() => {
								navigate("/profile");
							}}>
								View Profile
							</ViewProfileButton>
							}
						</div>
					</Row>
				</DropdownItem>
				<MenuItem divider/>
				{showOpenCloudLink &&
				<MenuItem tag={Link} to={"/redirect-cloud"}>
					Back to cloud
				</MenuItem>
				}
				{showUpgradePlanLink &&
				<MenuItem tag={Link} to={`/billing`}>
					Upgrade Plan
				</MenuItem>
				}
				<MenuItem onClick={() => logout()}>
					Sign Out
				</MenuItem>

			</Menu>
		</UncontrolledDropdown>
	</Nav>
);

UserMenu.propTypes = {
	picture: PropTypes.string.isRequired,
	showViewProfileLink: PropTypes.bool,
	showOpenCloudLink: PropTypes.bool,
	showUpgradePlanLink: PropTypes.bool,
	dark: PropTypes.bool
};

UserMenu.defaultProps = {
	showViewProfileLink: true,
	showOpenCloudLink: true,
	showUpgradePlanLink: false,
	dark: true
};


const mapStateToProps = (state) => ({
	user: state.myProfile.content || {},
	auth: state.authentication.auth
});

const mapDispatchToProps = (dispatch) => ({
	logout: () => dispatch(logout()),
	navigate: (url) => dispatch(push(url))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(UserMenu);
