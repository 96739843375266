import 'babel-polyfill';
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import "ladda/dist/ladda.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "./index.css";

import * as serviceWorker from './registerServiceWorker';

import App from "./App";
import { store, history } from "./redux/store";
import ErrorBoundary from "./components/error-boundary";

ReactDOM.render(
	<ErrorBoundary>
		<Provider store={store}>
			<App history={history}/>
		</Provider>
	</ErrorBoundary>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
