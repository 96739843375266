import { apiRequest } from "../actions/api";
import { CLOUD_USAGE_FULFILLED, CLOUD_USAGE_REJECTED, CLOUD_USAGE_REQUEST } from "../actions/cloud-usage";

const getCloudUsageProcess = ({ dispatch }) => next => action => {
	next(action);

	if (action.type === CLOUD_USAGE_REQUEST) {
		dispatch(apiRequest("GET", "/api/billing/cloud-usage", null, CLOUD_USAGE_FULFILLED, CLOUD_USAGE_REJECTED));
	}
};

export default [getCloudUsageProcess];
