import React, { Component } from "react";
import PropTypes from "prop-types";
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { connect } from "react-redux";

import { MyTeamActions } from "../utils/my-team";

class ModalLeaveTeam extends Component {

	static propTypes = {
		closeModal: PropTypes.func.isRequired
	};

	state = {
		error: null
	};

	close = () => {
		this.props.closeModal();
	};

	confirm = () => {

		this.setState({ error: null });

		MyTeamActions.leaveCurrentTeam()
			.then(() => this.props.auth.renewToken())
			.then(() => {
				this.close();
			})
			.catch((err) => {
				console.log(err);
				this.setState({ error: err.response.data.message });
			});
	};

	render() {
		return (
			<Modal
				autoFocus={1}
				isOpen={this.props.isOpen}
				fade={1}
				toggle={this.close}
				backdrop={1}>

				<ModalHeader>Leave Current Team</ModalHeader>

				<ModalBody>
					Are you sure you wish to leave the current team?
					{this.state.error && <Alert color={"danger"}>{this.state.error}</Alert>}
				</ModalBody>

				<ModalFooter>
					<Button color="secondary" onClick={this.close}>
						Close
					</Button>
					<Button color="primary" onClick={this.confirm}>
						Confirm
					</Button>
				</ModalFooter>
			</Modal>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	auth: state.authentication.auth
});

export default connect(
	mapStateToProps
)(ModalLeaveTeam);
