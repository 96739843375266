import {
	PAYMENT_HISTORY_FULFILLED,
	PAYMENT_HISTORY_REJECTED,
	PAYMENT_HISTORY_REQUEST
} from "../actions/payment-history";
import { apiRequest } from "../actions/api";

const getHistoryProcess = ({ dispatch }) => next => action => {
	next(action);

	if (action.type === PAYMENT_HISTORY_REQUEST) {
		dispatch(apiRequest("GET", "/api/billing/payment-history", null, PAYMENT_HISTORY_FULFILLED, PAYMENT_HISTORY_REJECTED))
	}
};

export default [getHistoryProcess];
