import React, { Component } from "react";
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";
import { Alert, Button, Col, Row } from "reactstrap";
import queryString from "querystring";
import { connect } from "react-redux";

import LoginLayout from "../../components/layout-login";
import { NAME_PATTERN } from "../../utils/constants";
import SmallHomeLink from "../../components/small-home-link";
import { REACT_APP_AUTH0_DOMAIN } from "../../environment";
import Authentication from "../../utils/Auth";

class InfoIsMissing extends Component {

    state = {
        formError: ""
    };

    handleValidSubmit = (event, values) => {
        const parsed = queryString.parse(window.location.search);

        const state = parsed["?state"] || parsed.state;
        const token = parsed["?token"] || parsed.token;

        const {payload} = Authentication.decodeIdToken(token);

        const {firstname, lastname} = values;

        this.props.auth.updateUserDetails(token, payload, firstname, lastname)
            .then(() => { window.location = `https://${REACT_APP_AUTH0_DOMAIN}/continue?token=${token}&state=${state}`; })
            .catch(err => {
                this.setState({
                    ...this.state,
                    formError: err.description || JSON.stringify(err)
                });
            });
    };

    render() {
        return (
            <LoginLayout>
                    <Row>
                        <Col className="d-flex justify-content-between align-items-center">
                            <div className="m-3">
                                <div className="show-md text-left">
                                    <SmallHomeLink/>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <div className="content-section">
                            <h2 className="text-left">Thanks for signing up.</h2>
                            <p className="lead text-left mb-5">We would like to get to know you better.</p>

                            <AvForm className="text-left" onValidSubmit={this.handleValidSubmit}>
                                <Row>
                                    <Col>
                                        <AvField
                                            name="firstname"
                                            label="FIRST NAME"
                                            type="text"
                                            placeholder="First name"
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage: "Please enter your first name"
                                                },
                                                pattern: {
                                                    value: NAME_PATTERN,
                                                    errorMessage: "Only A-Z, a-z and spaces are valid chars"
                                                },
                                                minLength: {
                                                    value: 2,
                                                    errorMessage: "Name must contain at least 2 chars"
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <AvField
                                            name="lastname"
                                            label="LAST NAME"
                                            type="text"
                                            placeholder="Last name"
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage: "Please enter your last name"
                                                },
                                                pattern: {
                                                    value: NAME_PATTERN,
                                                    errorMessage: "Only A-Z, a-z and spaces are valid chars"
                                                },
                                                minLength: {
                                                    value: 2,
                                                    errorMessage: "Name must contain at least 2 chars"
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>

                                {this.state.formError && <Alert color="danger">{this.state.formError}</Alert>}

                                <AvGroup className="text-center">
                                    <Button color="primary">Continue</Button>
                                </AvGroup>
                            </AvForm>
                        </div>
                    </Row>

                    <Row>
                        <Col>
                            <div className="text-center text-muted">
                                <p className="by-click">
                                    By signing up, you confirm that you agree to our{" "}
                                    <a rel="noreferrer noopener" target="_blank" href="https://seetest.io/legal">Terms
                                        of Use.</a>

                                </p>
                            </div>
                        </Col>
                    </Row>
            </LoginLayout>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
	auth: state.authentication.auth
});


export default connect(
	mapStateToProps
)(InfoIsMissing);
