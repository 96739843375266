import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import ModalCancelSubscription from "../components/modal-cancel-subscription";
import UncontrolledModal from "../components/uncontrolled-modal";

import { Button, Section, Separator } from "../components/styled-components";

import LoadingSpinner from "../components/loading-spinner";
import { loadMyProfile } from "../redux/actions/my-profile";
import LayoutBillingPage from "../components/layout-billing-page";
import { getTeamSubscription } from "../redux/actions/team-subscription";
import { willPayInNextPlan } from "../redux/reducers/team-subscription";


class AccountBilling extends Component {

	static displayName = "Billing";

	componentDidMount() {
		this.props.loadMyProfile();
		this.props.loadTeamSubscription();
	}

	render() {
		return (
			<LayoutBillingPage>

				<Fragment>

					{this.props.user && (
						<div>

							<Section>

								<div>
									<h2>My Subscription</h2>
								</div>

							</Section>

							{this.props.subscription &&
							<Fragment>

								<Section>

									<div>
										<h3>Active Plan</h3>
										<p>{this.props.subscription.planName}</p>
									</div>

								</Section>

								<Separator/>

								<Section>

									<div>
										<h3>Next Cycle</h3>
										<p>
											Your next renew cycle date is: <strong>{this.props.subscription.nextRenewDate}</strong>
											<br/>
											Your next plan is: <strong>{this.props.subscription.nextChargePlanName}</strong>
										</p>
									</div>

								</Section>

								{this.props.willPayInNextPlan &&
								<Fragment>

									<Separator/>

									<Section>

										<div>
											<h3>Cancel subscription</h3>
										</div>

										<div>
											<UncontrolledModal modalComponent={ModalCancelSubscription}>
												<Button width={200}>Cancel subscription</Button>
											</UncontrolledModal>
										</div>

									</Section>

								</Fragment>
								}

							</Fragment>
							}

						</div>
					)}

					{this.props.isLoading && (
						<LoadingSpinner className={"p-5 text-center"}/>
					)}

				</Fragment>

			</LayoutBillingPage>

		);
	}
}

const mapStateToProps = (state) => ({
	user: state.myProfile.content,
	isLoading: state.myProfile.isLoading,
	subscription: state.teamSubscription.content,
	willPayInNextPlan: willPayInNextPlan(state.teamSubscription)
});

const mapDispatchToProps = (dispatch) => ({
	loadMyProfile: () => dispatch(loadMyProfile()),
	loadTeamSubscription: () => dispatch(getTeamSubscription())
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AccountBilling);

