
export const FORM_RESET_MESSAGE = "[FORMS] FORM_RESET_MESSAGE";

export const resetFormMessage = (formName) => ({
	type: FORM_RESET_MESSAGE,
	payload: formName
});

export const FORM_SET_MESSAGE = "[FORMS] FORM_SET_MESSAGE";

export const setFormMessage = (formName, message, isError) => ({
	type: FORM_SET_MESSAGE,
	payload: {
		formName,
		message,
		isError
	}
});


