import {
	ID_REAUTH_REJECTED,
	SEND_PHONE_NUMBER_FULFILLED,
	SEND_PHONE_NUMBER_PENDING,
	SEND_PHONE_NUMBER_REJECTED,
	SEND_SMS_CODE_FULFILLED,
	SEND_SMS_CODE_PENDING,
	SEND_SMS_CODE_REJECTED
} from "../actions/sms-verification";
import { RESET_REQUEST } from "../actions/authentication";

const initialState = {
	step: "PHONE_REQUIRED",
	phoneNumber: "",
	dialCode: "",
	loading: false,
	error: null
};

const mySMSVerificationReducer = (state = initialState, action) => {

	switch (action.type) {

		case SEND_PHONE_NUMBER_PENDING: {
			const { phoneNumber, dialCode } = action.payload;
			return {
				...state,
				phoneNumber,
				loading: true,
				error: null,
				dialCode
			};
		}

		case SEND_PHONE_NUMBER_FULFILLED:
			return {
				...state,
				loading: false,
				error: null,
				step: "CODE_REQUIRED"
			};
		case SEND_PHONE_NUMBER_REJECTED:
			return {
				...state,
				loading: false,
				error: action.payload
			};


		case SEND_SMS_CODE_PENDING:
			return {
				...state,
				loading: true,
				error: null
			};

		case SEND_SMS_CODE_FULFILLED:
			return {
				...state,
				loading: false,
				step: "CODE_CONFIRMED"
			};

		case SEND_SMS_CODE_REJECTED:
			return {
				...state,
				loading: false,
				error: action.payload
			};

		case ID_REAUTH_REJECTED:
			return {
				...state,
				loading: false,
				error: action.payload,
				step: "ID_REAUTH_REJECTED"
			};

		case RESET_REQUEST:
			return initialState;

		default:
			return state;
	}
};

export default mySMSVerificationReducer;
