import React, { Component } from "react";
import { Alert, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import LoginLayout from "../../components/layout-login";
import SmallHomeLink from "../../components/small-home-link";
import { sendPhoneNumber, sendSmsCode } from "../../redux/actions/sms-verification";
import SmsPhoneForm from "../../components/sms-phone-form";
import SmsCodeForm from "../../components/sms-code-form";
import { logout } from "../../redux/actions/authentication";
import { PageLayover } from "../../components/styled-components";
import UserMenu from "../../components/user-menu";
import { loadMyProfile } from "../../redux/actions/my-profile";
import { Link } from "react-router-dom";

class SmsVerification extends Component {

	state = {};

	componentDidMount() {
		this.props.loadMyProfile();
	}

	render() {
		return (
			<LoginLayout>
				<PageLayover active={this.props.loading}/>
				<Row>

					<Col className="d-flex justify-content-between align-items-center">
						<div className="m-3">
							<div className="show-md text-left">
								<SmallHomeLink/>
							</div>
						</div>

						<p className="text-right text-help m-3">
							<UserMenu
								showOpenCloudLink={false}
								showUpgradePlanLink={false}
								showViewProfileLink={false}
								picture={this.props.idToken.picture}
								dark={false}
							/>
						</p>
					</Col>
				</Row>
				<Row>
					<div className="content-section">

						{this.props.step !== "CODE_REQUIRED" &&
						<h2 className="text-left">Phone Number Verification</h2>
						}

						{this.props.step === "CODE_REQUIRED" &&
						<h2 className="text-left">Enter your code</h2>
						}

						{this.props.step !== "CODE_CONFIRMED" && this.props.step !== "ID_REAUTH_REJECTED" &&
						<div>
							<p className="text-left">Please take a minute to verify your phone number.
								This helps us to confirm your identity and secure your account.</p>
							<br/>
						</div>
						}

						{this.props.step === "PHONE_REQUIRED" &&
						<SmsPhoneForm
							onValidSubmit={this.props.sendPhoneNumber}
							onCancelation={this.props.logout}
							error={(this.props.error) ? this.props.error.message : ""}
							loading={this.props.loading}
						/>
						}

						{this.props.step === "CODE_REQUIRED" &&
						<SmsCodeForm
							onValidSubmit={(smsCode) => this.props.sendSmsCode(this.props.phoneNumber, this.props.dialCode, smsCode)}
							onCancelation={this.props.logout}
							error={(this.props.error) ? this.props.error.message : ""}
							loading={this.props.loading}
							onResendCode={() => this.props.sendPhoneNumber(this.props.phoneNumber, this.props.dialCode)}
						/>
						}

						{this.props.step === "CODE_CONFIRMED" &&
						<div>
							<Alert color={"info"} className={"text-left"}>
								Your phone number was verified successfully, Please login to continue <Link
								to="/logout">(Login)</Link>
							</Alert>
						</div>
						}

						{this.props.step === "ID_REAUTH_REJECTED" &&
						<div>
							<Alert color={"info"} className={"text-left"}>
								Your phone number was verified successfully, Please login to continue <Link
								to="/logout">(Login)</Link>
							</Alert>
						</div>
						}
					</div>
				</Row>
				<Row/>

			</LoginLayout>
		);
	}
}

const mapStateToProps = (state) => ({
	error: state.smsVerification.error,
	auth: state.authentication.auth,
	idToken: state.authentication.idToken,
	step: state.smsVerification.step,
	loading: state.smsVerification.loading,
	phoneNumber: state.smsVerification.phoneNumber,
	dialCode: state.smsVerification.dialCode
});


const mapDispatchToProps = (dispatch) => ({
	sendPhoneNumber: (phoneNumber, dialCode) => dispatch(sendPhoneNumber(phoneNumber, dialCode)),
	sendSmsCode: (phoneNumber, dialCode, smsCode) => dispatch(sendSmsCode(phoneNumber, dialCode, smsCode)),
	loadMyProfile: () => dispatch(loadMyProfile()),
	logout: () => dispatch(logout())
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SmsVerification);
