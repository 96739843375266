import React from "react";
import PropTypes from "prop-types";

import '@fortawesome/fontawesome';
import "@fortawesome/fontawesome-free-brands";
import "@fortawesome/fontawesome-free-solid";

/**
 * @link https://fontawesome.com/how-to-use/use-with-node-js#api-reference
 */
export const Icon = ({name, brand}) => (
    <i className={`${brand ? 'fab' : 'fas'} fa-${name} ${name==='shopping-cart'?'shopping-cart':''} `} />
);

Icon.propTypes = {
    name: PropTypes.string.isRequired,
    brand: PropTypes.bool
};

Icon.defaultProps = {
    brand: false
};
