import React, { Component } from "react";
import PropTypes from "prop-types";

import styled from "styled-components";

const Col = styled.div`
	display: flex;
	flex-direction: column;
	
	min-height: 100vh;
    justify-content: space-between;
`;

const Row = styled.div`
	display: flex;
	flex-direction: row;
`;

const Box = styled.div`
	max-width: 100%;
	width: 500px;
	margin: auto;
	
	padding: 16px;
`;


class LayoutMessage extends Component {

	static propTypes = {
		children: PropTypes.arrayOf(PropTypes.element).isRequired
	};

	render() {
		return (
			<Col>
				<Row/>

				<Row>
					<Box>
						{ this.props.children }
					</Box>
				</Row>

				<Row />
			</Col>
		);
	}

}

export default LayoutMessage;
