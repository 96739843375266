
export const API_REQUEST = "[API] REQUEST";

export const apiRequest = (method, url, body, onSuccess, onFailure) => ({
	type: API_REQUEST,
	payload: body,
	meta: { method, url, onSuccess, onFailure }
});

export const API_CENCELLED = `[API] CANCELLED`;

export const ignoreApi = (method, url) => ({
	type: API_CENCELLED,
	payload: {
		method,
		url
	}
});
