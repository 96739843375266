import React, {Component} from 'react'
import {Alert, Container} from "reactstrap";

import {MyTeamActions} from "../utils/my-team";
import ConfirmInvitationPrompt from "../components/confirm-invitation-prompt";

class ConfirmInvitation extends Component {

    state = {
        loading: true,
        message: "",
        invitation: null
    };

    componentWillMount() {

        const {linkId} = this.props.match.params;

		MyTeamActions.getInvitationById(linkId)
            .then((invitation) => {
                this.setState({invitation, loading: false});
            })
            .catch((err) => {

                if (err.response.status === 422) {
                    this.setState({success: false, message: "invalid parameters", loading: false});
                }

                else {
                    this.setState({
                        message: err.response.data.message,
                        loading: false
                    });
                }

            });
    }

    render() {
        return (
            <div>
                <Container>
                    
                    {this.state.loading && <h2>loading</h2>}

                    {!this.state.loading && this.state.invitation &&
                    <ConfirmInvitationPrompt invitation={this.state.invitation}
                                             linkId={this.props.match.params.linkId}/>
                    }

                    {!this.state.loading && !this.state.invitation &&
                    <Alert color={"danger"}>{this.state.message}</Alert>
                    }


                </Container>
            </div>
        )
    }
}

export default ConfirmInvitation;
