import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import { Badge } from "reactstrap";
import { show } from "redux-modal";

import { Link } from "react-router-dom";

import UncontrolledModal from "../components/uncontrolled-modal";
import ModalLeaveTeam from "../components/modal-leave-team";

import { Button, Section } from "../components/styled-components";
import { getMyTeam } from "../redux/actions/my-team";
import { loadMyProfile } from "../redux/actions/my-profile";
import LoadingSpinner from "../components/loading-spinner";
import TeamMembersList from "../components/team-members-list";
import LayoutTeamPage from "../components/layout-team-page";
import { getTeamSubscription } from "../redux/actions/team-subscription";

class MyTeamPage extends Component {

	static displayName = "MyTeam";

	componentDidMount() {
		this.props.loadMyTeam();
		this.props.loadMyProfile();
		this.props.loadTeamSubscription();
	}

	render() {

		return (
			<LayoutTeamPage>


				{this.props.team && (
					<Fragment>
						<Section>

							<div>
								<div>
									<h2>
										My Team
									</h2>

									<p>
										{this.props.team.teamName} {" "}
										<Badge pill className={"ml-2"}>{this.props.team.myRole}</Badge>
									</p>
								</div>
							</div>

							<div>
								{this.props.team.myRole !== "admin" &&
								<UncontrolledModal modalComponent={ModalLeaveTeam}
												   onClose={this.props.forceLoadMyTeam}>
									<Button width={150}>Leave</Button>
								</UncontrolledModal>
								}
							</div>

						</Section>

						<Section>

							<div>

								<h3>
									Team members
								</h3>

								<p>
									<Link to={"/team/invitations"}>Click here</Link> to invite people to your team
								</p>

								<TeamMembersList updateMyTeam={this.props.forceLoadMyTeam}
												 members={this.props.team.teamMembers}
												 currentUser={this.props.user}/>

							</div>
						</Section>

					</Fragment>
				)}

				{this.props.error && (
					<div className={"p-3"}>
						{this.props.error.message}
					</div>
				)}

				{this.props.isLoading && <LoadingSpinner className={"p-5 text-center"}/>}


			</LayoutTeamPage>
		);
	}
}

const mapStateToProps = (state) => ({
	team: state.myTeam.content,
	error: state.myTeam.error,
	isLoading: state.myTeam.isLoading,
	user: state.myProfile.content
});

const mapDispatchToProps = (dispatch) => ({
	loadMyTeam: () => dispatch(getMyTeam(true)),
	loadTeamSubscription: () => dispatch(getTeamSubscription()),
	loadMyProfile: () => dispatch(loadMyProfile()),
	forceLoadMyTeam: () => dispatch(getMyTeam(true)),
	openModal: (name, props) => dispatch(show(name, props))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MyTeamPage);
