/* eslint-disable no-debugger,camelcase */
import React, { Component } from "react";
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";
import PropTypes from "prop-types";
import { Alert, Button, Col, Row } from "reactstrap";
import { EXPAND_LEFT } from "react-ladda";
import { SubmitButton } from "./styled-components";

export default class SmsCodeForm extends Component {

	static propTypes = {
		onValidSubmit: PropTypes.func.isRequired,
		onCancelation: PropTypes.func.isRequired,
		onResendCode: PropTypes.func.isRequired,
		error: PropTypes.string,
		loading: PropTypes.bool
	};

	static defaultProps = {
		error: null,
		loading: false
	};

	state = {
		resendMessage: false,
		resendTimeout: null
	};

	componentDidMount() {
	 	const resendTimeout = setTimeout(() => {
			this.setState({ resendMessage: true });
		}, 15000);
	 	this.setState({
			resendTimeout
		});
	}
	componentWillUnmount() {
		window.clearTimeout(this.state.resendTimeout);
	}

	openIntercom = () => {
		window.Intercom('show');
	}

	handleCancelation = () => {
		this.props.onCancelation();
	};

	handleValidSubmit = (event, values) => {
		const { smsCode } = values;
		this.props.onValidSubmit(smsCode);
	};

	handleResendCode = () => {
		this.props.onResendCode();
	}

	render() {

		return (
			<div>
				<AvForm className="text-left" onValidSubmit={this.handleValidSubmit}>
					<Row>
						<Col>
							<label> Please enter your SMS Code</label>
							<AvField
								name="smsCode"
								autofocus="true"
								type="text"
								placeholder="Code"
								validate={{
									required: {
										value: true,
										errorMessage: "Please enter your code"
									},
									minLength: {
										value: 4,
										errorMessage: "Name must contain at least 2 chars"
									}
								}}
							/>

						</Col>
					</Row>
					{this.props.error && <Alert color="danger">{this.props.error}</Alert>}
					<br/>
					<AvGroup className="text-left">
						<SubmitButton
							width={150}
							loading={this.props.loading ? 1 : 0}
							data-color="#eee"
							data-style={EXPAND_LEFT}
							data-spinner-color="#ddd">
							Continue
						</SubmitButton>
						<Button type="button" color="default" className="cancel-btn"
								onClick={this.handleCancelation}>Cancel</Button>
					</AvGroup>
				</AvForm>
				{this.state.resendMessage &&
					<p className="text-left">Didn't receive a text message? Click &nbsp;
						<Button color="link" onClick={this.openIntercom} style={{ color: 'orange' }}>here</Button> for support or &nbsp;
						<Button color="link" onClick={this.handleResendCode} style={{ color: 'orange' }}>resend</Button> code.
					</p>
				}
			</div>
		);
	}
}
