import React, { Component } from "react";
import PropTypes from "prop-types";
import { ConnectedRouter } from "connected-react-router";
import {connect} from "react-redux";

import routes from "./routes";
import { registerHistory } from "./tracking";

import "./App.css";

class App extends Component {

	static propTypes = {
		history: PropTypes.object.isRequired
	};

	componentDidMount() {
		registerHistory(this.props.history);
	}

	render() {
		const { history } = this.props;

		return (
			<ConnectedRouter history={history}>
				{routes(this.props.auth)}
			</ConnectedRouter>

		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	auth: state.authentication.auth
});

export default connect(
	mapStateToProps
)(App);
