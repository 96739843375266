import React, { Component } from "react";
import { Link } from "react-router-dom";
import { AvField, AvForm, AvGroup, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation";
import { Alert, Col, Label, Row } from "reactstrap";
import { EXPAND_LEFT } from "react-ladda";
import { connect } from "react-redux";

import * as queryString from "query-string";

import LoginLayout from "../../components/layout-login";
import { LOGIN_PROVIDER, NAME_PATTERN, PASSWORD_PATTERN } from "../../utils/constants";
import { LinkedinButton, GoogleButton } from "../../components/social-buttons";
import OrDivider from "../../components/or-divider";
import SmallHomeLink from "../../components/small-home-link";
import { PageLayover, SubmitButton } from "../../components/styled-components";
import { loginWithConnection, signUpWithCredentials } from "../../redux/actions/authentication";

class Signup extends Component {

	componentWillMount() {

		const { returnTo, source, medium, campaign, term } = queryString.parse(this.props.router.location.search);
		source && localStorage.setItem("source", source);
		medium && localStorage.setItem("medium", medium);
		campaign &&	localStorage.setItem("campaign", campaign);
		term && localStorage.setItem("term", term);

		const referrer = document.referrer || "";
		console.log(document.referrer || "no referrer");
		localStorage.setItem("referrer", referrer);

		if (returnTo) {
			console.log(`setting redirect link to: ${returnTo}`);
			localStorage.setItem("pending_redirect", returnTo);
		}
	}

	handleValidSubmit = (event, values) => {
		const { company, email, country, firstname, lastname, password } = values;
		this.props.signUpWithCredentials(company, email, country, firstname, lastname, password);
	};

	render() {
		return (
			<LoginLayout>
				<PageLayover active={this.props.loading}/>
				<Row>
					<Col className="d-flex justify-content-between align-items-center">
						<div className="m-3">
							<div className="show-md text-left">
								<SmallHomeLink/>
							</div>
						</div>

						<p className="text-right text-help m-3">
							<span className={"hide-sm"}>
								Already have an account?
							</span>
							<Link className="btn btn-primary ml-3 text-help" to="/login">
								Login
							</Link>
						</p>
					</Col>
				</Row>

				<Row>
					<div className="content-section">
						<h2 className="text-left">Get started absolutely free.</h2>

						<Row style={{ display: 'none' }}>
							<div className="mb-2 col-4">
								<GoogleButton
									onClick={() => this.props.signUpWithConnection(LOGIN_PROVIDER.GOOGLE)}
									disabled={this.props.loading}
								/>
							</div>
							<div className="mb-2 col-4">
								<LinkedinButton
									onClick={() => this.props.signUpWithConnection(LOGIN_PROVIDER.LINKEDIN)}
									disabled={this.props.loading}
								/>
							</div>
						</Row>

						<Row style={{ display: 'none' }}><OrDivider /></Row>

						<AvForm
							className="text-left"
							onValidSubmit={this.handleValidSubmit}>
							<Row>
								<Col md={6}>
									<AvField
										name="firstname"
										label="FIRST NAME"
										type="text"
										placeholder="First name"
										disabled={this.props.loading}
										validate={{
											required: {
												value: true,
												errorMessage: "Please enter your first name"
											},
											pattern: {
												value: NAME_PATTERN,
												errorMessage: "Only A-Z, a-z and spaces are valid chars"
											},
											minLength: {
												value: 2,
												errorMessage: "Name must contain at least 2 chars"
											}
										}}
									/>
								</Col>
								<Col md={6}>
									<AvField
										name="lastname"
										label="LAST NAME"
										type="text"
										placeholder="Last name"
										disabled={this.props.loading}
										validate={{
											required: {
												value: true,
												errorMessage: "Please enter your last name"
											},
											pattern: {
												value: NAME_PATTERN,
												errorMessage: "Only A-Z, a-z and spaces are valid chars"
											},
											minLength: {
												value: 2,
												errorMessage: "Name must contain at least 2 chars"
											}
										}}
									/>
								</Col>
							</Row>

							<AvField
								name="company"
								label="COMPANY"
								type="text"
								placeholder="Company name"
								disabled={this.props.loading}
								validate={{
									required: {
										value: true,
										errorMessage: "Please enter your company name"
									},
									pattern: {
										value: new RegExp("^.*[a-zA-Z0-9].*[a-zA-Z0-9].*$"),
										errorMessage: "Company must contain at least 2 chars"
									},
									minLength: {
										value: 2,
										errorMessage: "Company must contain at least 2 chars"
									},
									maxLength: {
										value: 255,
										errorMessage: "Company must contain at most 255 chars"
									}
								}}
							/>

							<AvField
								name="email"
								label="COMPANY EMAIL"
								type="email"
								placeholder="your@email.com"
								disabled={this.props.loading}
								validate={{
									required: {
										value: true,
										errorMessage: "Please enter your email address"
									},
									email: {
										value: true,
										errorMessage: "Email is invalid"
									}
								}}
							/>

							<AvField
								type="select"
								name="country"
								label="COUNTRY"
								placeholder="United States"
								validate={{
									required: {
										value: true,
										errorMessage: "Please select a country"
									}}}
							>
								<option>United States</option>
								<option>Afghanistan</option>
								<option>Albania</option>
								<option>Algeria</option>
								<option>American Samoa</option>
								<option>Andorra</option>
								<option>Angola</option>
								<option>Anguilla</option>
								<option>Antarctica</option>
								<option>Antigua & Barbuda</option>
								<option>Argentina</option>
								<option>Armenia</option>
								<option>Aruba</option>
								<option>Ascension Island</option>
								<option>Australia</option>
								<option>Austria</option>
								<option>Azerbaijan</option>
								<option>Bahamas</option>
								<option>Bahrain</option>
								<option>Bangladesh</option>
								<option>Barbados</option>
								<option>Belarus</option>
								<option>Belgium</option>
								<option>Belize</option>
								<option>Benin</option>
								<option>Bermuda</option>
								<option>Bhutan</option>
								<option>Bolivia</option>
								<option>Bosnia & Herzegovina</option>
								<option>Botswana</option>
								<option>Brazil</option>
								<option>British Indian Ocean Territory</option>
								<option>Brunei</option>
								<option>Bulgaria</option>
								<option>Burkina Faso</option>
								<option>Burundi</option>
								<option>Cambodia</option>
								<option>Cameroon</option>
								<option>Canada</option>
								<option>Cape Verde</option>
								<option>Cayman Islands</option>
								<option>Central African Republic</option>
								<option>Chad</option>
								<option>Chile</option>
								<option>China</option>
								<option>Christmas Island</option>
								<option>Cocos Islands</option>
								<option>Colombia</option>
								<option>Comoros</option>
								<option>Congo (Dem Rep of)</option>
								<option>Congo</option>
								<option>Cook Islands</option>
								<option>Costa Rica</option>
								<option>Croatia</option>
								<option>Cuba</option>
								<option>Cyprus</option>
								<option>Czech Republic</option>
								<option>Denmark</option>
								<option>Djibouti</option>
								<option>Dominica</option>
								<option>Dominican Republic</option>
								<option>East Timor</option>
								<option>Ecuador</option>
								<option>Egypt</option>
								<option>El Salvador</option>
								<option>Equatorial Guinea</option>
								<option>Eritrea</option>
								<option>Estonia</option>
								<option>Ethiopia</option>
								<option>Falkland Islands</option>
								<option>Faroe Islands</option>
								<option>Fiji</option>
								<option>Finland</option>
								<option>France</option>
								<option>French Guiana</option>
								<option>French Polynesia</option>
								<option>French Southern Territories</option>
								<option>Gabon</option>
								<option>Gambia</option>
								<option>Georgia</option>
								<option>Germany</option>
								<option>Ghana</option>
								<option>Gibraltar</option>
								<option>Greece</option>
								<option>Greenland</option>
								<option>Grenada</option>
								<option>Guadeloupe</option>
								<option>Guam</option>
								<option>Guatemala</option>
								<option>Guernsey</option>
								<option>Guinea</option>
								<option>Guinea-Bissau</option>
								<option>Guyana</option>
								<option>Haiti</option>
								<option>Heard Island and McDonald Islands</option>
								<option>Honduras</option>
								<option>Hong Kong</option>
								<option>Hungary</option>
								<option>Iceland</option>
								<option>India</option>
								<option>Indonesia</option>
								<option>Iran</option>
								<option>Iraq</option>
								<option>Ireland</option>
								<option>Isle Of Man</option>
								<option>Israel</option>
								<option>Italy</option>
								<option>Ivory Coast</option>
								<option>Jamaica</option>
								<option>Japan</option>
								<option>Jersey</option>
								<option>Jordan</option>
								<option>Kazakhstan</option>
								<option>Kenya</option>
								<option>Kiribati</option>
								<option>Korea (North)</option>
								<option>Korea (South)</option>
								<option>Kosovo</option>
								<option>Kuwait</option>
								<option>Kyrgyzstan</option>
								<option>Laos</option>
								<option>Latvia</option>
								<option>Lebanon</option>
								<option>Lesotho</option>
								<option>Liberia</option>
								<option>Libya</option>
								<option>Liechtenstein</option>
								<option>Lithuania</option>
								<option>Luxembourg</option>
								<option>Macao</option>
								<option>Macedonia</option>
								<option>Madagascar</option>
								<option>Malawi</option>
								<option>Malaysia</option>
								<option>Maldives</option>
								<option>Mali</option>
								<option>Malta</option>
								<option>Marshall Islands</option>
								<option>Martinique</option>
								<option>Mauritania</option>
								<option>Mauritius</option>
								<option>Mayotte</option>
								<option>Mexico</option>
								<option>Micronesia (Fed States of)</option>
								<option>Moldova</option>
								<option>Monaco</option>
								<option>Mongolia</option>
								<option>Montenegro</option>
								<option>Montserrat</option>
								<option>Morocco</option>
								<option>Mozambique</option>
								<option>Myanmar</option>
								<option>Namibia</option>
								<option>Nauru</option>
								<option>Nepal</option>
								<option>Netherlands Antilles</option>
								<option>Netherlands</option>
								<option>New Caledonia</option>
								<option>New Zealand</option>
								<option>Nicaragua</option>
								<option>Niger</option>
								<option>Nigeria</option>
								<option>Niue</option>
								<option>Norfolk Island</option>
								<option>Northern Mariana Islands</option>
								<option>Norway</option>
								<option>Oman</option>
								<option>Pakistan</option>
								<option>Palau</option>
								<option>Palestinian Territory</option>
								<option>Panama</option>
								<option>Papua New Guinea</option>
								<option>Paraguay</option>
								<option>Peru</option>
								<option>Philippines</option>
								<option>Pitcairn Island</option>
								<option>Poland</option>
								<option>Portugal</option>
								<option>Puerto Rico</option>
								<option>Qatar</option>
								<option>Reunion</option>
								<option>Romania</option>
								<option>Russian Federation</option>
								<option>Rwanda</option>
								<option>Samoa</option>
								<option>San Marino</option>
								<option>Sao Tome & Principe</option>
								<option>Saudi Arabia</option>
								<option>Senegal</option>
								<option>Serbia</option>
								<option>Seychelles</option>
								<option>Sierra Leone</option>
								<option>Singapore</option>
								<option>Slovakia</option>
								<option>Slovenia</option>
								<option>Solomon Islands</option>
								<option>Somalia</option>
								<option>South Africa</option>
								<option>Spain</option>
								<option>Sri Lanka</option>
								<option>St Helena</option>
								<option>St Kitts & Nevis</option>
								<option>St Lucia</option>
								<option>St Martin</option>
								<option>St Pierre & Miquelon</option>
								<option>St Vincent & the Grenadines</option>
								<option>Sudan</option>
								<option>Suriname</option>
								<option>Svalbard and Jan Mayen</option>
								<option>Swaziland</option>
								<option>Sweden</option>
								<option>Switzerland</option>
								<option>Syria</option>
								<option>Taiwan</option>
								<option>Tajikistan</option>
								<option>Tanzania</option>
								<option>Thailand</option>
								<option>Togo</option>
								<option>Tokelau Is.</option>
								<option>Tonga</option>
								<option>Trinidad & Tobago</option>
								<option>Tristan da Cunha</option>
								<option>Tunisia</option>
								<option>Turkey</option>
								<option>Turkmenistan</option>
								<option>Turks & Caicos Islands</option>
								<option>Tuvalu</option>
								<option>Uganda</option>
								<option>Ukraine</option>
								<option>United Arab Emirates</option>
								<option>United Kingdom</option>
								<option>Uruguay</option>
								<option>Uzbekistan</option>
								<option>Vanuatu</option>
								<option>Venezuela</option>
								<option>Vietnam</option>
								<option>Virgin Islands (British)</option>
								<option>Virgin Islands (US)</option>
								<option>Wallis & Futuna Is.</option>
								<option>Western Sahara</option>
								<option>Yemen</option>
								<option>Zambia</option>
								<option>Zimbabwe</option>option>
							</AvField>

							<AvField
								name="password"
								label="PASSWORD"
								type="password"
								placeholder="Enter your password"
								disabled={this.props.loading}
								validate={{
									required: {
										value: true,
										errorMessage: "Please enter your password"
									},
									pattern: {
										value: PASSWORD_PATTERN,
										errorMessage: "Password must contain UPPER, lower, and numbers (no special characters)"
									},
									minLength: {
										value: 8,
										errorMessage: "Password is too short"
									}
								}}
							/>

							<AvCheckboxGroup inline className={"mb-4"} name="agree" required errorMessage="You must agree in order to continue.">
								<Label check>
									<AvCheckbox value="yes"/>Yes, I agree to receive periodic emails from Digital.ai related to products and services and can unsubscribe at any time. I accept the{" "}<a href="https://digital.ai/privacy-policy" rel="noopener noreferrer" target="_blank">Privacy Policy</a> ,{" "}<a href="https://digital.ai/terms-use" rel="noopener noreferrer" target="_blank">Terms of Service</a> and is not entitled to support.
								</Label>
							</AvCheckboxGroup>

							{this.props.error && <Alert color="danger">{this.props.error}</Alert>}

							<AvGroup className="text-center">
								<SubmitButton
									loading={this.props.loading ? 1 : 0}
									data-style={EXPAND_LEFT}
									width={150}>
									Signup
								</SubmitButton>
							</AvGroup>
						</AvForm>
					</div>
				</Row>

				<Row/>

			</LoginLayout>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	error: state.login.error,
	loading: state.login.loading,
	router: state.router
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	signUpWithConnection: (connection) => dispatch(loginWithConnection(connection)),
	signUpWithCredentials: (company, email, country, firstname, lastname, password, source, medium, campaign, term) => dispatch(signUpWithCredentials(company, email, country, firstname, lastname, password, source, medium, campaign, term))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Signup);
