
export const HIDE_SPINNER = "[UI] HIDE_SPINNER";

export const hideSpinner = () => ({
	type: HIDE_SPINNER
});

export const SHOW_SPINNER = "[UI] SHOW_SPINNER";

export const showSpinner = () => ({
	type: SHOW_SPINNER
});
