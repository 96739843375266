
import api from "./api";
import myTeam from "./my-team";
import myProfile from "./my-profile";
import authentication from "./authentication";
import paymentHistory from "./payment-history";
import cloudUsage from "./cloud-usage";
import teamSubscription from "./team-subscription";
import smsVerification from "./sms-verification";


export default [
	...authentication,
	...api,
	...myTeam,
	...myProfile,
	...paymentHistory,
	...teamSubscription,
	...cloudUsage,
	...smsVerification
];
