import React from "react";
import styled from "styled-components";
import { Col } from "reactstrap";

import "./layout-login.css";

const LeftSection = styled.div`
	width: 20%;
    min-width: 300px;
    max-width: 350px;
    position: fixed;  
`;

const LoginLayout = (props) => (

    <div className={`d-flex flex-row no-overflow ${props.className}`}>
        <LeftSection className="text-center bg-section d-flex flex-column justify-content-center hide-lg h-100vh">
            <div className="bg-heading">
				<p className="bg-heading-title">Welcome to Digital.ai</p>
				<p className="bg-heading-text">Continuous Testing public platform</p>
				<a href="https://digital.ai/" target="_blank" rel="noopener noreferrer">
					<img src="/product-logos.svg" width="260" height="24" alt="product-logos"/>
				</a>
            </div>
        </LeftSection>
		<Col className={"text-center d-flex flex-1 flex-column justify-content-between h-100vh allow-overflow custom-ml"}>
			{props.children}
		</Col>
    </div>
);

export default LoginLayout;
