import { push } from "connected-react-router";
import {
	failedToReauthId,
	SAVE_USER_PHONE_FULFILLED,
	SAVE_USER_PHONE_PENDING,
	SAVE_USER_PHONE_REJECTED,
	SEND_PHONE_NUMBER_FULFILLED,
	SEND_PHONE_NUMBER_PENDING,
	SEND_PHONE_NUMBER_REJECTED,
	SEND_SMS_CODE_FULFILLED,
	SEND_SMS_CODE_PENDING,
	SEND_SMS_CODE_REJECTED,
	sendSavePhone
} from "../actions/sms-verification";
import { apiRequest } from "../actions/api";
import { refreshIdToken } from "../actions/authentication";

const getSMSVerificationFlow = ({ dispatch, getState }) => next => action => {
	next(action);

	if (action.type === SEND_PHONE_NUMBER_PENDING) {
		dispatch(apiRequest("POST", "/api/sms/start", action.payload, SEND_PHONE_NUMBER_FULFILLED, SEND_PHONE_NUMBER_REJECTED));
	}

	if (action.type === SEND_PHONE_NUMBER_REJECTED) {
		console.log("Request is rejected", action.payload);

		const {statusCode} = action.payload;

		if (statusCode === 706) {
			getState().authentication.auth.renewToken()
				.then(() => dispatch(refreshIdToken()))
				.then(() => dispatch(push("/")))
				.catch(err => {
					console.error(err);
					dispatch(failedToReauthId(err));
				});
		}
	}

	if (action.type === SEND_SMS_CODE_PENDING) {
		dispatch(apiRequest("POST", "/api/sms/check", action.payload, SEND_SMS_CODE_FULFILLED, SEND_SMS_CODE_REJECTED));
	}

	if (action.type === SEND_SMS_CODE_FULFILLED) {
		const {phoneNumber, dialCode} =  getState().smsVerification;
		dispatch(sendSavePhone(phoneNumber, dialCode));
	}

	if (action.type === SAVE_USER_PHONE_PENDING) {
		dispatch(apiRequest("PUT", "/api/sms/phone", action.payload, SAVE_USER_PHONE_FULFILLED, SAVE_USER_PHONE_REJECTED));
	}

	if (action.type === SAVE_USER_PHONE_FULFILLED) {



		getState().authentication.auth.renewToken()
			.then(() => dispatch(refreshIdToken()))
			.then(() => dispatch(push("/redirect-cloud")))
			.catch(err => {
				console.error(err);
				dispatch(failedToReauthId(err));
			})
	}

	if (action.type === SEND_SMS_CODE_REJECTED) {
		console.log("Request is rejected", action.payload);
	}

};

export default [getSMSVerificationFlow];
