
import { PAYMENT_HISTORY_FULFILLED, PAYMENT_HISTORY_REJECTED, PAYMENT_HISTORY_REQUEST } from "../actions/payment-history";
import { RESET_REQUEST } from "../actions/authentication";

const initialState = {
	fetching: false,
	payments: []
};

const reducer = (state = initialState, action) => {

	switch (action.type) {

		case PAYMENT_HISTORY_REQUEST:
			return {
				...state,
				fetching: true
			};

		case PAYMENT_HISTORY_FULFILLED:
			return {
				...state,
				payments: action.payload,
				fetching: false
			};

		case PAYMENT_HISTORY_REJECTED:
			return {
				...state,
				fetching: false
			};

		case RESET_REQUEST:
			return initialState;

		default:
			return state;
	}
};

export default reducer;
