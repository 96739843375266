import auth0 from "auth0-js";
import Cookies from "js-cookie";

import {
	REACT_APP_AUTH0_DOMAIN,
	REACT_APP_AUTH0_CLIENTID,
	REACT_APP_COOKIE_DOMAIN, REACT_APP_AUTH0_CLAIN_NAMESPACE
} from "../environment";

import { LOGIN_PROVIDER } from "./constants";

function assertNotEmpty(value, field = "untitled", rejectFn = null) {
	if (value && value.length && value.length > 0) {
		return;
	}

	const error = {
		description: `ASSRSTION ERROR: field ${field} is empty`
	};

	if (rejectFn) {
		rejectFn(error);
	}

	throw new Error(error);
}

const REDIRECT_URL = `${window.location.origin}/callback`;

class Authentication {

	userProfile;
	tokenRenewalTimeout;

	auth0 = new auth0.WebAuth({
		domain: REACT_APP_AUTH0_DOMAIN,
		clientID: REACT_APP_AUTH0_CLIENTID,
		redirectUri: REDIRECT_URL,
		responseType: "token id_token",
		scope: "openid profile"
	});

	constructor(history) {
		this.history = history;
		this.scheduleRenewal();
	}


	signUpWithCredentials = (company, email, country, firstName, lastName, password) => {
		return new Promise((resolve, reject) => {

			const source = localStorage.getItem('source') || "no";
			const campaign = localStorage.getItem('campaign') || "no";
			const medium = localStorage.getItem('medium') || "no";
			const term = localStorage.getItem('term') || "no";
			const referrer = localStorage.getItem('referrer') || "no";
			assertNotEmpty(company, "email", reject);
			assertNotEmpty(email, "email", reject);
			assertNotEmpty(firstName, "firstName", reject);
			assertNotEmpty(lastName, "lastName", reject);
			assertNotEmpty(password, "password", reject);

			this.auth0.signup(
				{
					connection: "Username-Password-Authentication",
					email,
					password,
					user_metadata: {
						given_name: firstName,
						family_name: lastName,
						agree_terms: "true",
						company: company,
						given_country: country,
						source,
						medium,
						campaign,
						term,
						referrer
					}
				},
				(err, authResult) => {
					if (authResult) {
						if (authResult.emailVerified === false) {
							reject("emailVerification");
						} else {
							this.setSession(authResult);
							resolve();
						}
					} else if (err) {
						reject(err);
					}
				}
			);
		});
	};


	loginWithCredentials = (username, password) => new Promise((resolve, reject) => {

		assertNotEmpty(username, "username", reject);
		assertNotEmpty(password, "password", reject);

		// this.auth0.popup.loginWithCredentials({
		// this.auth0.popup.authorize({
		this.auth0.client.login({
			realm: "Username-Password-Authentication",
			username,
			password
		}, (err, authResult) => {

			if (err) {
				reject(err);
				return;
			}

			try {

				this.auth0.validateToken(authResult.idToken, null, (err3, idTokenPayload) => {

					this.setSession(authResult);

					if (this.isAuthenticated()) {
						resolve();
					} else {
						reject({
							description: "Unexpected error"
						});
					}
				});
			}

			catch (err2) {
				reject(err2);
			}
		});

	});

	loginWithProvider = (provider) => new Promise((resolve, reject) => {

		if (Object.values(LOGIN_PROVIDER).indexOf(provider) === -1) {
			throw new Error("invalid login provider");
		}

		this.auth0.popup.authorize({ connection: provider }, (err, authResult) => {

			if (this.isAuthenticated()) {
				resolve(authResult);
			}

			else if (err) reject(err);

			else resolve(authResult);
		});
	});

	/**
	 * @deprecated
	 */
	clearAuth0Localstorage = () => {

		Object.keys(window.localStorage)
			.filter(key => key.startsWith("com.auth0.auth."))
			.forEach(key => window.localStorage.removeItem(key));
	};

	handlePopupAuthentication = () => {

		this.auth0.parseHash((err, authResult) => {

			if (authResult && authResult.accessToken && authResult.idToken && authResult.idTokenPayload) {
				this.setSession(authResult);
			}

			this.auth0.popup.callback();
		});
	};


	setSession = (authResult) => {

		// Set the time that the access token will expire at
		const expiresAt = JSON.stringify(
			(authResult.expiresIn * 1000) + new Date().getTime()
		);

		localStorage.setItem("access_token", authResult.accessToken);
		localStorage.setItem("id_token", authResult.idToken);
		localStorage.setItem("expires_at", expiresAt);

		const expiresInDays = authResult.expiresIn / (60 * 60 * 24);

		Cookies.set("id_token", authResult.idToken, {
			domain: REACT_APP_COOKIE_DOMAIN,
			path: "/",
			expires: expiresInDays
		});

		Cookies.set("access_token", authResult.accessToken, {
			domain: REACT_APP_COOKIE_DOMAIN,
			path: "/",
			expires: expiresInDays
		});

		Cookies.set("isAuthenticated", "true", {
			domain: REACT_APP_COOKIE_DOMAIN,
			path: "/",
			expires: expiresInDays
		});

		// schedule a token renewal
		this.scheduleRenewal();
	};

	logout = () => {

		// Clear access token and ID token from local storage
		localStorage.removeItem("access_token");
		localStorage.removeItem("id_token");
		localStorage.removeItem("expires_at");
		localStorage.removeItem("scopes");

		Cookies.remove("id_token", {
			domain: REACT_APP_COOKIE_DOMAIN,
			path: "/"
		});

		Cookies.remove("access_token", {
			domain: REACT_APP_COOKIE_DOMAIN,
			path: "/"
		});

		Cookies.remove("isAuthenticated", {
			domain: REACT_APP_COOKIE_DOMAIN,
			path: "/"
		});

		this.userProfile = null;
		clearTimeout(this.tokenRenewalTimeout);

		// // navigate to the home route
		// this.history.replace("/");
	};

	isAuthenticated = () => {

		// Check whether the current time is past the
		// access token's expiry time

		const expires = localStorage.getItem("expires_at");

		if (!expires) {
			return false;
		}

		const expiresAt = JSON.parse(expires);
		return new Date().getTime() < expiresAt;
	};

	isSmsVerificationRequired = () => {

		const idTokenData = Authentication.getIdTokenData();

		const isRequired = idTokenData[`${REACT_APP_AUTH0_CLAIN_NAMESPACE}sms_required`] === "true";
		return isRequired;
	};

	isCloudUserValid = () => {

		const idTokenData = Authentication.getIdTokenData();

		const hasUserId = idTokenData[`${REACT_APP_AUTH0_CLAIN_NAMESPACE}cloud_user_id`];
		const hasProjectId = idTokenData[`${REACT_APP_AUTH0_CLAIN_NAMESPACE}cloud_project_id`];
		return hasProjectId && hasUserId;
	};

	renewToken = () => new Promise((success, reject) => {

		this.auth0.checkSession({}, (err, result) => {

			if (err) {
				if (err.error === "login_required") {
					// From: Alex
					// Not quite sure what it means, but it happens in QA environment. Also the auth0 documentation says:
					// If the connection is a social connection and you are using Auth0 dev keys, the checkSession call will always return login_required.
					success();
					return;
				}
				console.error("failed to renew token", err);
				reject(err);

			} else {
				this.setSession(result);
				success();
			}
		});
	});


	scheduleRenewal = () => {

		const expires = localStorage.getItem("expires_at");

		if (!expires) {
			return;
		}

		const expiresAt = JSON.parse(expires);
		const delay = expiresAt - Date.now();
		if (delay > 0) {
			this.tokenRenewalTimeout = setTimeout(() => {
				this.renewToken();
			}, delay);
		}
	};

	updateUserWithSource = (idToken, profile) => new Promise((resolve, reject) => {

		try {

			const auth0Manage = new auth0.Management({
				domain: REACT_APP_AUTH0_DOMAIN,
				token: idToken
			});

			const source = localStorage.getItem('source') || "no";
			const campaign = localStorage.getItem('campaign') || "no";
			const medium = localStorage.getItem('medium') || "no";
			const term = localStorage.getItem('term') || "no";

			const userMetadata = {
				source,
				campaign,
				medium,
				term
			};

			auth0Manage.patchUserMetadata(profile.sub, userMetadata, (err, result) => {
				if (err) {
					reject(err);
				} else {
					resolve(result);
				}
			});

		} catch (err) {
			console.error("error in updateUserDetails", err);
			reject(err);
		}
	});

	updateUserDetails = (idToken, profile, firstName, lastName) => new Promise((resolve, reject) => {

		try {

			assertNotEmpty(firstName, "First Name", reject);
			assertNotEmpty(lastName, "Last Name", reject);

			const auth0Manage = new auth0.Management({
				domain: REACT_APP_AUTH0_DOMAIN,
				token: idToken
			});

			const userMetadata = {
				given_name: firstName,
				family_name: lastName,
				agree_terms: "true"
			};

			auth0Manage.patchUserMetadata(profile.sub, userMetadata, (err, result) => {
				if (err) {
					reject(err);
				} else {
					resolve(result);
				}
			});

		} catch (err) {
			console.error("error in updateUserDetails", err);
			reject(err);
		}
	});

	// validateSMSVerificationFields = (phoneNumber, dialCode, smsCode) => new Promise((resolve, reject) => {
	// 	if (!smsCode) {
	// 		try {
	// 			assertNotEmpty(phoneNumber, "Phone Number", reject);
	// 			resolve();
	// 		} catch (err) {
	// 			console.error("error in validateSMSVerificationFields", err);
	// 			reject(err);
	// 		}
	// 	} else {
	// 		try {
	// 			assertNotEmpty(smsCode, "Code", reject);
	// 			resolve();
	// 		} catch (err) {
	// 			console.error("error in validateSMSVerificationFields", err);
	// 			reject(err);
	// 		}
	// 	}
	// });


	requestPasswordReset = (email) => new Promise((resolve, reject) => {

		assertNotEmpty(email, "email", reject);

		this.auth0.changePassword(
			{
				connection: "Username-Password-Authentication",
				email
			},
			(err, response) => {
				if (err) reject(err);
				else resolve(response);
			}
		);
	});

	static decodeIdToken(token) {
		const parts = token.split(".");
		let header;
		let payload;

		if (parts.length !== 3) {
			throw Error("Cannot decode a malformed JWT");
		}

		try {
			header = JSON.parse(atob(parts[0]));

			// no idea why it works! black magic!
			const part1 = parts[1].replace("-", "+").replace("_", "/");
			payload = JSON.parse(atob(part1));
		} catch (e) {
			throw Error("Token header or payload is not valid JSON");
		}

		return {
			header,
			payload,
			original: token,
			encoded: {
				header: parts[0],
				payload: parts[1],
				signature: parts[2]
			}
		};
	}


	static getIdTokenData() {
		const idToken = localStorage.getItem("id_token");

		try {

			if (idToken) {
				const { payload } = this.decodeIdToken(idToken);
				return payload || {};
			}
		}

		catch (e) {
			console.error("failed to parse idToken", e);
		}

		return {};
	}
}

export default Authentication;
