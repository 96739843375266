import React, { Component } from "react";
import { CSSTransition }  from 'react-transition-group';
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {cancelSubscription} from "../utils/checkout-actions";


class ModalCancelSubscription extends Component {

    state = {
        error: null,
		success: false
    };

    close = () => {
        this.props.closeModal();
    };


    confirm = () => {

        cancelSubscription()
        	.then(() => {

				this.setState({
					// success: true
					showAnimation: true
				});

				setTimeout(() => {
					this.close();
				}, 3 * 1000);
        	})
        	.catch((err) => {
        		this.setState({ error: err.message });
        	});
    };

    render() {
        return (
            <Modal
                autoFocus={false}
                isOpen={this.props.isOpen}
                fade={true}
                backdrop={true}>
                <ModalHeader>Cancel Subscription</ModalHeader>
                <ModalBody>

					<p>
						You're about to cancel your subscription. Are you sure?
					</p>

					<CSSTransition
						in={this.state.showAnimation}
						timeout={1000}
						classNames="message"
						unmountOnExit

					>
						{state => (
							<Alert color="success">
								Your subscription has been successfully canceled.
							</Alert>
						)}
					</CSSTransition>


                    {this.state.error && <Alert>{this.state.error}</Alert>}

                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.close}>
                        Cancel
                        </Button>
                    <Button color="secondary" onClick={this.confirm}>
                        Confirm
                        </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default ModalCancelSubscription;
