import axios from "axios";

export class MyTeamActions {

	// static getMyTeam() {
	// 	return axios.get("/api/teams/")
	// 		.then(res => res.data);
	// }

	static inviteAnEmail(email) {
		return axios.post("/api/teams/invitations", {
			email
		});
	}

	static getInvitationById(linkId) {
		return axios.get(`/api/teams/invitations/${linkId}`)
			.then(res => res.data);
	}

	static deleteEmailInvitation(email) {
		return axios.delete("/api/teams/invitations", {
			data: {
				email
			}
		});
	}

	static leaveCurrentTeam() {
		return axios.delete("/api/teams/members", {
			data: {
				email: "current@user.mail"
			}
		});
	}

	static removeMemberFromTeam(email) {
		return axios.delete("/api/teams/members", {
			data: {
				email
			}
		});
	}

	static confirmInvitation(linkId) {

		return axios.post("/api/teams/invitations/confirmation", {
			linkId
		});
	}

	static rejectInvitation(linkId) {
		return axios.post("/api/teams/invitations/reject", {
			linkId
		});
	}


}

