import React, { Component } from "react";
import { connect } from "react-redux";
import { logout } from "../../redux/actions/authentication";

class Logout extends Component {

	componentDidMount() {
		this.props.logout();
	}

	render() {
		return (
			<div></div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	logout: () => dispatch(logout())
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Logout);
