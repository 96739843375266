import { REACT_APP_INTERCOM_APP_ID } from "../environment";
import Authentication from "./Auth";

export async function bootIntercom() {

	return new Promise((resolve, reject) => {

		try {

			const idToken = localStorage.getItem("id_token");
			const { payload } = Authentication.decodeIdToken(idToken);

			window.intercomSettings = {
				app_id: REACT_APP_INTERCOM_APP_ID,
				user_id: payload.sub
			};

			window.Intercom("boot", {
				app_id: REACT_APP_INTERCOM_APP_ID,
				user_id: payload.sub
			});

		} catch (err) {

			console.error("failed to update intercom with new user");
			console.error(err);

		} finally {
			resolve();
		}
	});
}

export function resetIntercom() {

	try {

		window.Intercom("boot", { app_id: REACT_APP_INTERCOM_APP_ID });


	} catch (err) {

		console.error("failed to reset intercom");
		console.error(err);

	}

}
