import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table } from "reactstrap";

class PaymentHistoryList extends Component {

	static propTypes = {
		payments: PropTypes.arrayOf(PropTypes.shape({
			transactionId: PropTypes.string,
			transactionDate: PropTypes.string,
			invoiceId: PropTypes.string,
			amount: PropTypes.number,
			currency: PropTypes.string
		})).isRequired
	};

	state = {};

	render() {

		return (
			<Table className={"table-borderless"}>
				<thead>
				<tr>
					<th>#</th>
					<th>Invoice Number</th>
					<th>Amount</th>
					<th>Date</th>
				</tr>
				</thead>
				<tbody>

				{this.props.payments.map((payment, index) => (
					<tr key={payment.transactionId}>
						<th scope="row">{index+1}</th>
						<td>{payment.invoiceId || "Unknown"}</td>
						<td>{`${payment.amount} ${payment.currency}`}</td>
						<td>{payment.transactionDate}</td>
					</tr>
				))}

				</tbody>
			</Table>
		);
	}

}

export default PaymentHistoryList;
