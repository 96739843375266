import { Col, Row } from "reactstrap";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import queryString from "query-string";

import LoginLayout from "../../components/layout-login";
import SmallHomeLink from "../../components/small-home-link";

class EmailVerification extends Component {
	state = {};

	componentDidMount() {
		/**
		 * @type {{email: string, message: string, success: "true" | "false"}}
		 * supportSignUp=true&supportForgotPassword=true&email=r2436153%40mvrht.net&message=Your%20email%20was%20verified.%20You%20can%20continue%20using%20the%20application.&success=true#
		 */
		const parsed = queryString.parse(window.location.search);
		const additionalParameters = window.location.hash.replace("#", "");

		if (parsed) {
			this.setState({
				...parsed,
				experitest: additionalParameters === "experitest"
			});
		}
	}

	render() {
		const loginPageLink = this.state.experitest ? "/login?source=experitest" : "/login";

		return (
			<LoginLayout>
				<Row>
					<Col className="d-flex justify-content-between align-items-center">
						<div className="m-3">
							<div className="show-md text-left">
								<SmallHomeLink/>
							</div>
						</div>
					</Col>
				</Row>
				<Row className={"flex-1"}>
					<Col className="d-flex flex-column justify-content-center align-items-center">

						{this.state.success === "true" ? (
							<div className={"text-center"}>
								<h2>Great! You have successfully activated your account</h2>
								<Link to={loginPageLink} className="text-center btn btn-primary mt-5">
									Click Here To Login
								</Link>
							</div>
						) : (
							<div
								className={
									"content-section text-left d-flex flex-column justify-content-center align-items-center"
								}>
								<h1>Something went wrong...</h1>
								<h2>{this.state.message}</h2>
								<br/>
								<div className="d-flex flex-column justify-content-center align-items-center mb-4">
									<img src="/broken_envlp.png" alt="error in link"
										 className="img-responsive img-fluid"/>
								</div>
								<p className="lead">
									We were unable to verify your email. <br/>
								</p>

								<p className="lead">
									<a className="btn btn-primary" rel="noreferrer noopener" target="_blank"
									   href="https://seetest.io/contact">Contact us for more details</a>
								</p>
							</div>
						)}
					</Col>
				</Row>
			</LoginLayout>
		);
	}
}

export default EmailVerification;
