import React, { Component } from "react";
import { connect } from "react-redux";

import LayoutTeamPage from "../components/layout-team-page";
import { Button, Section } from "../components/styled-components";
import InvitationList from "../components/invitation-list";
import { getMyTeam } from "../redux/actions/my-team";
import { loadMyProfile } from "../redux/actions/my-profile";
import UncontrolledModal from "../components/uncontrolled-modal";
import ModalInviteMember from "../components/modal-invite-member";
import LoadingSpinner from "../components/loading-spinner";

class TeamInvitationsPage extends Component {

	componentDidMount() {
		this.props.loadMyTeam();
		this.props.loadMyProfile();
	}

	render() {

		return (
			<LayoutTeamPage>

				<Section>

					<div>
						<h2>Team Invitations</h2>
					</div>

				</Section>

				<Section>

					<div>
						Invite members to your team.
					</div>

					<div>
						{this.props.team && this.props.team.myRole === "admin" &&
						<UncontrolledModal modalComponent={ModalInviteMember}
										   onClose={this.props.forceLoadMyTeam}>
							<Button width={150}>Invite</Button>
						</UncontrolledModal>
						}
					</div>

				</Section>

				<Section>


					{!(this.props.team && this.props.user) &&
					<LoadingSpinner/>
					}


					{this.props.team && this.props.user &&
					<div>
						<h3>Pending invitations</h3>

						<InvitationList updateMyTeam={this.props.forceLoadMyTeam}
										invitations={this.props.team.pendingInvitations}
										currentUser={this.props.user}/>

					</div>
					}

				</Section>

			</LayoutTeamPage>
		);
	}

}


const mapStateToProps = (state) => ({
	team: state.myTeam.content,
	error: state.myTeam.error,
	isLoading: state.myTeam.isLoading,
	user: state.myProfile.content
});

const mapDispatchToProps = (dispatch) => ({
	loadMyTeam: () => dispatch(getMyTeam(true)),
	forceLoadMyTeam: () => dispatch(getMyTeam(true)),
	loadMyProfile: () => dispatch(loadMyProfile())
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(TeamInvitationsPage);

