import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import ProfilePage from "./pages/profile";
import ProfilePreferencesPage from "./pages/profile-preferences";

import BillingPage from "./pages/billing";
import BillingHistoryPage from "./pages/billing-history";
import CloudUsagePage from "./pages/cloud-usage";

import MyTeamPage from "./pages/my-team";
import TeamInvitationsPage from "./pages/team-invitations";

import NoMatch from "./pages/no-match";

import LogoutPage from "./pages/auth/logout";
import LoginPage from "./pages/auth/login";
import SignupPage from "./pages/auth/signup";
import EmailUnverifiedPage from "./pages/auth/email-unverified";
import EmailVerificationPage from "./pages/auth/email-verification";
import ForgotPasswordPage from "./pages/auth/forgot-password";
import InfoIsMissingPage from "./pages/auth/info-is-missing";
import NoConfirmationAuthLogin from "./pages/auth/no-confirmation-auth-login";

import SmsVerificationPage from "./pages/auth/sms-verification";

import Callback from "./pages/auth/callback";

import ConfirmInvitationPage from "./pages/confirm-invitation";

import PrivateRoute from "./components/private-route";
import AnonymousRoute from "./components/anonymous-route";
import ExternalLink from "./components/external-link";

import { REDIRECT_PAGES } from "./utils/constants";
import DeleteAccountSuccess from "./pages/delete-account-success";


const routes = (auth) => (
	<div>
		<Switch>
			<Route exact path="/" render={() =>
				auth.isAuthenticated() ?
					<Redirect to="/profile"/> :
					<Redirect to="/login"/>
			}/>

			<Redirect exact from="/profile" to="/account/info"/>
			<Redirect exact from="/account" to="/account/info"/>
			<PrivateRoute exact path="/account/info" component={ProfilePage}/>
			<PrivateRoute exact path="/account/preferences" component={ProfilePreferencesPage}/>

			<Redirect exact from="/billing" to="/billing/subscription"/>
			<PrivateRoute exact path="/billing/subscription" component={BillingPage}/>
			<PrivateRoute exact path="/billing/history" component={BillingHistoryPage}/>
			<PrivateRoute exact path="/billing/usage" component={CloudUsagePage}/>

			<Redirect exact from="/team" to="/team/members"/>
			<Redirect exact from="/my-team" to="/team/members"/>
			<PrivateRoute exact path="/team/members" component={MyTeamPage}/>
			<PrivateRoute exact path="/team/invitations" component={TeamInvitationsPage}/>

			<PrivateRoute exact path="/upgrade/plans" render={() => <ExternalLink to={REDIRECT_PAGES.CONTACT_US_LANDING_PAGE.uri}/>}/>

			<PrivateRoute exact path="/upgrade/ex-plans" render={() => <ExternalLink to={REDIRECT_PAGES.CONTACT_US_LANDING_PAGE.uri}/>}/>

			<PrivateRoute exact path="/confirm-invitation/:linkId" component={ConfirmInvitationPage}/>

			<Route exact path="/redirect-cloud"
				   render={() => {
					   if (auth.isCloudUserValid()) {
						   return <ExternalLink to={REDIRECT_PAGES.CLOUD_LAUNCHPAD.uri}/>;
					   } else {
						   return <Redirect to={"/"}/>;
					   }
				   }}/>

			<AnonymousRoute exact path="/login" component={LoginPage}/>
			<AnonymousRoute exact path="/signup" component={SignupPage}/>
			<AnonymousRoute exact path="/email-unverified" component={EmailUnverifiedPage}/>
			<AnonymousRoute exact path="/email-verification" component={EmailVerificationPage}/>
			<AnonymousRoute exact path="/forgot-password" component={ForgotPasswordPage}/>
			<AnonymousRoute exact path="/info-is-missing" component={InfoIsMissingPage}/>
			<AnonymousRoute exact path="/signup-confirmation" component={NoConfirmationAuthLogin}/>

			<Route exact path="/sms-verification"
				   render={() => {
					   if (auth.isSmsVerificationRequired()) {
						   return <SmsVerificationPage/>;
					   } else {
						   return <Redirect to={"/redirect-cloud"}/>;
					   }
				   }}
			/>

			<Route exact path="/callback" render={(props) => {
				auth.handlePopupAuthentication(props);
				return <Callback {...props} />;
			}}/>

			<Route exact path="/logout" component={LogoutPage} />

			<Route exact path="/delete-account-success" component={DeleteAccountSuccess}/>

			<Route component={NoMatch}/>

		</Switch>
	</div>
);

export default routes;
