import {
	DELETE_MY_ACCOUNT_FULFILLED, DELETE_MY_ACCOUNT_OPEN_DIALOG,
	DELETE_MY_ACCOUNT_REJECTED,
	DELETE_MY_ACCOUNT_REQUEST
} from "../actions/my-profile";
import { RESET_REQUEST } from "../actions/authentication";


const initialState = {
	sending: false,
	error: null
};

const reducer = (state = initialState, action) => {

	switch (action.type) {

		case DELETE_MY_ACCOUNT_OPEN_DIALOG:
			return initialState;

		case DELETE_MY_ACCOUNT_REQUEST:
			return {
				...state,
				sending: true,
				error: null
			};

		case DELETE_MY_ACCOUNT_FULFILLED:
			return {
				...state,
				sending: false,
				error: null
			};

		case DELETE_MY_ACCOUNT_REJECTED:
			return {
				...state,
				sending: false,
				error: action.payload
			};

		case RESET_REQUEST:
			return initialState;

		default:
			return state;
	}
};

export default reducer;
