import { GET_ID_TOKEN, RESET_REQUEST } from "../actions/authentication";
import Authentication from "../../utils/Auth";


const initialState = () => ({
	idToken: Authentication.getIdTokenData(),
	auth: new Authentication()
});

const authenticationReducer = (state = initialState(), action) => {

	switch (action.type) {

		case GET_ID_TOKEN:
			return {
				...state,
				idToken: action.payload
			};

		case RESET_REQUEST:
			return initialState();

		default:
			return state;
	}
};


export default authenticationReducer;
