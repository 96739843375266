module.exports = [
	"yahoo.co.uk",
	"0hdear.com",
	"0hiolce.com",
	"10minut.xyz",
	"163.com",
	"20mm.eu",
	";id",
	"Agshdu.com",
	"anom.com",
	"aol.com",
	"applause.com",
	"avilevi.com",
	"binka.me",
	"bk.ru",
	"borland.com",
	"checksdqd",
	"crazymail.guru",
	"deyom.com",
	"dxc",
	"emailnax.com",
	"endrix.org",
	"freemail.hu",
	"gbouquete.com",
	"getairmail.com",
	"getnada.com",
	"giyam.com",
	"gmail.com",
	"gmx.com",
	"gmx.es",
	"gmx.info",
	"gmx.net",
	"googlemail.com",
	"hideemail.net",
	"hotmail.co.uk",
	"hotmail.com",
	"hotmail.fr",
	"hotmail.se",
	"hp.com",
	"hpe.com",
	"huawei.com",
	"i.ua",
	"icloud.com",
	"inbox.ru",
	"inboxbear.com",
	"ipdeer.com",
	"live.com",
	"live.nl",
	"ln0rder.com",
	"mail.bg",
	"mail.com",
	"mail.ru",
	"mailforspam.com",
	"mailinator",
	"mailmetal.com",
	"me.com",
	"microfocus.com",
	"mobilelabsinc.com",
	"morsin.com",
	"mvrht.com",
	"mvrht.net",
	"my.com",
	"my10minutemail.com",
	"neotys.com",
	"nsogroup.com",
	"nu.edu.pk",
	"outlook.com",
	"perfecto.io",
	"perfectomobile.com",
	"perforce.com",
	"psoxs",
	"q.com",
	"qq.com",
	"rediffmail.com",
	"resmail24.com",
	"rocketmail",
	"rocketmail.com",
	"rockmail",
	"rockmail.com",
	"sapo.pt",
	"saucelabs.com",
	"sharklasers.com",
	"sjuaq.com",
	"smartbear.com",
	"soasta.com",
	"some.com",
	"spam4.me",
	"tbabs.co.uk",
	"testplant.com",
	"ua.fm",
	"verizon.net",
	"walla",
	"yahoo",
	"yahoo.ca",
	"yahoo.co.in",
	"yahoo.com",
	"yahoo.com.au",
	"yahoo.es",
	"yandex",
	"yandex.com",
	"yandex.ru",
	"yaooo.com",
	"yeah.net",
	"ymail.com",
	"yopmail",
	"yopmail.com",
	"send22u.info",
	"mozej.com",
	"usa.com",
	"ahk.jp",
	"youzend.com",
	"mohmal.in",
	"mohmal.im",
	"mohmal.tech",
	"mailna.co",
	"mailna.in",
	"mailna.me",
	"mailna.biz",
	"emailo.pro",
	"loanins.org",
	"cryp.email",
	"koyocah.ml",
	"storent.co",
	"raimucok.cf",
	"didikselowcoffee.ml",
	"ugreatejob.pw",
	"kotsu01.info",
	"b2email.win",
	"max88.club",
	"visa-securepay.cf",
	"baban.ml",
	"grabitfast.co",
	"smaretboy.pw",
	"jumnoor4036.ga",
	"mitsubishi-asx.tk",
	"mail4-us.org",
	"ss00.gq",
	"wudet.men",
	"if58.cf",
	"babau.gq",
	"jancokancene.ml",
	"tiuop.com",
	"sojoa.com",
	"sojfya.com",
	"soioa.com",
	"ckotie.com",
	"miauj.com",
	"zamge.com",
	"zabme.com",
	"zabge.com",
	"ckoie.com",
	"tyuiop.com",
	"zompi.com",
	"pdold.com",
	"soifa.com",
	"bnuis.com",
	"mvauj.com",
	"kumail8.info",
	"bozab.com",
	"spambox.me",
	"xvx.us",
	"loapq.com",
	"tintmok.com",
	"xvx.com",
	"imoraji.com",
	"timok.com",
	"maroco.tfwno.gfcom",
	"hakima.com",
	"kalobink.com",
	"compitt.com",
	"carins.io",
	"garethtech.cf",
	"wazir.com",
	"bnuiss.com",
	"workmail.com",
	"free.fr",
	"ma.iut.ac.ir",
	"lonic.com",
	"wazirm.com",
	"onmicrosoft.com",
	"p33.org",
	"uni",
	"edu",
	"dispostable.com",
	"mailinbox.co",
	"jackmailer.com",
	"robot-mail.com",
	"robo3.site",
	"1website.net",
	"nicemail.pro",
	"tfwno.gf",
	"cock.li",
	"airmail.cc",
	"8chan.co",
	"redchan.it",
	"420blaze.it",
	"aaathats3as.com",
	"cumallover.me",
	"dicksinhisan.us",
	"loves.dicksinhisan.us",
	"wants.dicksinhisan.us",
	"dicksinmyan.us",
	"loves.dicksinmyan.us",
	"goat.si",
	"horsefucker.org",
	"national.shitposting.agency",
	"nigge.rs",
	"cock.lu",
	"cock.email",
	"firemail.cc",
	"getbackinthe.kitchen",
	"memeware.net",
	"cocaine.ninja",
	"waifu.club",
	"rape.lol",
	"nuke.africa",
	"agentcloud.com",
	"f7scene.com",
	"yenimail.site",
	"dr.com",
	"mailkutusu.site",
	"mailadresim.site",
	"uzxia.com",
	"mailox.biz",
	"getamailbox.org",
	"mymailfire.com",
	"champmails.com",
	"updates9z.com",
	"0n0ff.net",
	"fastmail",
	"ln0ut.net",
	"cris@empowering-communities.org",
	"next2cloud.info",
	"alexbox.online",
	"321-email.com",
	"utooemail.com",
	"22office.com",
	"heximail.com",
	"4senditnow.com",
	"braun4email.com",
	"onecitymail.com",
	"utoo.email",
	"grr.la",
	"guerrillamail.biz",
	"guerrillamail.com",
	"guerrillamail.de",
	"guerrillamail.net",
	"guerrillamail.org",
	"guerrillamailblock.com",
	"ranorex.com",
	"tricentis.com",
	"pcloudy.com",
	"headspin.io",
	"crossbrowsertesting.com",
	"kobiton.com",
	"plutocow.com"
]
