import Authentication from "../../utils/Auth";

export const GET_ID_TOKEN = "[AUTH] GET_ID_TOKEN";

export const RESET_REQUEST = "[AUTH] RESET_REQUEST";

export const LOGOUT_REQUEST = "[AUTH] LOGOUT_REQUEST";

export const LOGIN_CONNECTION_PENDING = "[AUTH] LOGIN_CONNECTION_PENDING";

export const LOGIN_CREDENTIALS_PENDING = "[AUTH] LOGIN_CREDENTIALS_PENDING";

export const SIGNUP_CREDENTIALS_PENDING = "[AUTH] SIGNUP_CREDENTIALS_PENDING";

export const LOGIN_FULFILLED = "[AUTH] LOGIN_FULFILLED";

export const UPDATE_SOURCE = "[AUTH] UPDATE_SOURCE";

export const LOGIN_REJECTED = "[AUTH] LOGIN_REJECTED";

export const signUpWithCredentials = (company, email, country, firstname, lastname, password, source, medium, campaign, term ) => ({
	type: SIGNUP_CREDENTIALS_PENDING,
	payload: { company, email, country, firstname, lastname, password, source, medium, campaign, term }
});

export const loginWithCredentials = (email, password) => ({
	type: LOGIN_CREDENTIALS_PENDING,
	payload: { email, password }
});

export const loginWithConnection = (connection) => ({
	type: LOGIN_CONNECTION_PENDING,
	payload: connection
});


export const loginSuccess = () => ({
	type: LOGIN_FULFILLED
});

export const updateSource = () => ({
	type: UPDATE_SOURCE
});

export const loginError = (err) => ({
	type: LOGIN_REJECTED,
	payload: err
});

export const logout = (redirectUrl = "/", rememberLastPage = true) => ({
	type: LOGOUT_REQUEST,
	payload: redirectUrl,
	meta: {
		rememberLastPage
	}
});

export const refreshIdToken = () => ({
	type: GET_ID_TOKEN,
	payload: Authentication.getIdTokenData()
});


export const resetAllStores = () => ({
	type: RESET_REQUEST
});
