import React from "react";
import styled from "styled-components";

import {Separator} from "./styled-components";
import media from "../utils/style-utils";

const FooterWrapper = styled.footer`
    //margin: auto;
    //padding: 0 20px;
    
    color: #ccc;
    font-size: 14px;    

    & a {
        color: #ccc;
    }
    
    & a:hover {
        color: black;
    }
`;

const Sections = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 15px 0;
    
    ${media.lg`
        flex-direction: column;
        
        & [flex] {
            display: none;
        }
    `}
    
    & > * {
        margin: 5px;
    }
    
    & [flex] {
      flex: 1;
    }
`;

const Footer = () => (
    <FooterWrapper>
        <Separator/>
        <Sections>

            <span>&copy; 2021 Digital.ai Software, Inc.,</span>
            <span>All Rights Reserved</span>

            <span flex="true"/>

            <a href="https://digital.ai/legal" rel="noopener noreferrer" target="_blank">Legal Notice</a>
            <a href="https://digital.ai/terms-use" rel="noopener noreferrer" target="_blank">Terms of Use.</a>

        </Sections>
    </FooterWrapper>
);

export default Footer;
