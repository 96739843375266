import React from "react";
import { Container, Row, Col } from "reactstrap";

import { ContentContainer, PageContainer } from "./styled-components";
import Header from "./accounts-header";
import Footer from "./footer";

const LayoutFullPage = ({ children, Menu }) => (
	<PageContainer>

		<Header/>

		<Container className={"flex-1"}>

			<Row>

				<Col sm={4} md={3}>
					<Menu/>
				</Col>

				<Col sm={8} md={9}>
					<ContentContainer>
						{children}
					</ContentContainer>
				</Col>
			</Row>

		</Container>

		<Container>
			<Footer/>
		</Container>

	</PageContainer>
);

export default LayoutFullPage;
