import React from 'react';
import {Col, Row} from "reactstrap";

import styled from "styled-components";

const Text = styled.div`
	height: 60px;
	line-height: 60px;
	margin-top: -10px;
	width: 100%;
	text-align: center;
	font-size: 17px;
	font-weight: 500;
	color: #3d3d41;
`;

const OrDivider = (props) => (

	<Row className={ `mb-4 mt-4 ${  props.className || ""}` }>

		<Col className="pt-2">
			<hr/>
		</Col>
		<Col className="col-auto pt-1">
			<Text>or</Text>
		</Col>
		<Col className="pt-2">
			<hr/>
		</Col>
	</Row>
);


export default OrDivider;
