
export const GET_TEAM_SUB_PENDING = "[TEAM] GET_TEAM_SUB_PENDING";

export const getTeamSubscription = (ignoreCache = false) => ({
	type: GET_TEAM_SUB_PENDING,
	meta: {
		ignoreCache
	}
});

export const GET_TEAM_SUB_FULFILLED = "[TEAM] GET_TEAM_SUB_FULFILLED";

export const GET_TEAM_SUB_REJECTED = "[TEAM] GET_TEAM_SUB_REJECTED";

export const GET_TEAM_SUB_CANCELLED = "[TEAM] GET_TEAM_SUB_CANCELLED";
