import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import LayoutBillingPage from "../components/layout-billing-page";
import { Section } from "../components/styled-components";
import PaymentHistoryList from "../components/payment-history-list";
import LoadingSpinner from "../components/loading-spinner";
import { isPayingUser } from "../redux/reducers/my-profile";
import { fetchPaymentHistory } from "../redux/actions/payment-history";

class BillingHistoryPage extends Component {

	static displayName = "BillingHistory";

	componentDidMount() {
		this.props.loadMyPaymentHistory();
	}

	render() {
		return (


			<LayoutBillingPage>

				<Fragment>

					{this.props.payments && (
						<div>
							<Section>

								<h2>Payment History</h2>

							</Section>

							<Section>
								<PaymentHistoryList payments={this.props.payments}/>
							</Section>

						</div>
					)}

					{this.props.isLoading && (
						<LoadingSpinner className={"p-5 text-center"}/>
					)}

				</Fragment>

			</LayoutBillingPage>

		);
	}

}


const mapStateToProps = (state) => ({
	isPayingUser: isPayingUser(state.myProfile),
	isLoading: state.paymentHistory.fetching,
	payments: state.paymentHistory.payments
});

const mapDispatchToProps = (dispatch) => ({
	loadMyPaymentHistory: () => dispatch(fetchPaymentHistory())
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(BillingHistoryPage);
