import { push, replace } from "connected-react-router";
import { GET_PROFILE_REJECTED } from "../actions/my-profile";
import {
	LOGIN_CONNECTION_PENDING,
	LOGIN_CREDENTIALS_PENDING,
	LOGIN_FULFILLED,
	loginError,
	loginSuccess,
	updateSource,
	logout,
	LOGOUT_REQUEST,
	refreshIdToken,
	resetAllStores,
	SIGNUP_CREDENTIALS_PENDING, UPDATE_SOURCE
} from "../actions/authentication";
import { bootIntercom } from "../../utils/intercom";
import { postLogin, postLogout } from "../../utils/experitest-integration";

const badDomains = require("./badDomains")
const badDomains2 = require("./badDomains2")

const rejectUnauthorizedUser = ({ dispatch }) => next => action => {

	if (action.type === GET_PROFILE_REJECTED) {

		const err = action.payload;

		if (err.statusCode === 401) {
			localStorage.setItem("pending_redirect", window.location.href);
			dispatch(logout("/", false));
		}
	}

	return next(action);

};

const resetStateOnLogout = ({ dispatch, getState }) => next => async action => {

	next(action);

	if (action.type === LOGOUT_REQUEST) {

		const { rememberLastPage } = action.meta;

		getState().authentication.auth.logout();

		await postLogout().catch((err) => console.error(`failed to sync experitest with iframe: `, err));

		dispatch(resetAllStores());

		if (rememberLastPage) {
			dispatch(push(action.payload));
		} else {
			dispatch(replace(action.payload));
		}

	}
};

const loginWithConnection = ({ dispatch, getState }) => next => action => {

	next(action);

	if (action.type === LOGIN_CONNECTION_PENDING) {

		getState().authentication.auth.clearAuth0Localstorage();

		const provider = action.payload;

		getState().authentication.auth.loginWithProvider(provider)
			.catch(err => {

				if (err.original && err.original instanceof SyntaxError) {
					console.log("bug in winchan library", err);
				} else {
					throw err;
				}

			})
			.then(() => dispatch(refreshIdToken()))
			.then(() => dispatch(loginSuccess()))
			.then(() => dispatch(updateSource()))
			.then(() => dispatch(push("/redirect-cloud")))
			.catch(err => dispatch(loginError(err.description)));
	}

};

const loginWithCredentials = ({ dispatch, getState }) => next => action => {

	next(action);

	if (action.type === LOGIN_CREDENTIALS_PENDING) {
		getState().authentication.auth.clearAuth0Localstorage();

		const { email, password } = action.payload;

		getState().authentication.auth.loginWithCredentials(email, password)
			.catch(err => {

				if (err.original && err.original instanceof SyntaxError) {
					console.log("bug in winchan library", err);
				} else {
					throw err;
				}

			})
			.then(() => dispatch(refreshIdToken()))
			.then(() => dispatch(loginSuccess()))
			.then(() => dispatch(push("/redirect-cloud")))
			.catch(err => dispatch(loginError(err.description)));
	}

};

function blockEmail(email)  {
	const emailDomain = email.replace(/.*@/, "").toLowerCase();
	const regex = new RegExp('.*_XPRtestD@.*');
	if (badDomains.includes(emailDomain)) {
		return true;
	}
	if (regex.test(email)) {
		return false;
	}
	return badDomains2.includes(emailDomain);
}

const signUpWithCredentials = ({ dispatch, getState }) => next => action => {

	next(action);

	if (action.type === SIGNUP_CREDENTIALS_PENDING) {

		getState().authentication.auth.clearAuth0Localstorage();

		const { company, email, country, firstname, lastname, password, source, medium, campaign, term } = action.payload;

		// Check company email
		if (blockEmail(email)) {
			dispatch(loginError(`Only company email is allowed`));
		} else {
			getState().authentication.auth.signUpWithCredentials(company, email, country, firstname, lastname, password, source, medium, campaign, term)
				.catch(err => {

					if (err.original && err.original instanceof SyntaxError) {
						console.log("bug in winchan library", err);
					} else {
						throw err;
					}

				})
				.then(() => dispatch(refreshIdToken()))
				.then(() => dispatch(loginSuccess()))
				.then(() => dispatch(push("/redirect-cloud")))
				.catch(err => {
					if (err === "emailVerification") {
						dispatch(push("/email-unverified"));
					} else {
						dispatch(loginError(err.description));
					}
				});
		}
	}
};

const syncExperitestWithUser = ({ dispatch, getState }) => next => async action => {

	next(action);

	if (action.type === LOGIN_FULFILLED) {

		try {

			await bootIntercom();
			await postLogin();

		} catch (err) {
			console.error(`failed to sync experitest with iframe: `, err);
		}
		dispatch(push("/redirect-cloud"));
	}
};

const updateUTMSource = ({ dispatch, getState }) => next => action => {

	next(action);

	if (action.type === UPDATE_SOURCE) {
		const idToken = localStorage.getItem("id_token");
		const profile = getState().authentication.idToken;

		getState().authentication.auth.updateUserWithSource(idToken, profile);
	}
};

export default [
	rejectUnauthorizedUser,
	resetStateOnLogout,
	loginWithConnection,
	loginWithCredentials,
	signUpWithCredentials,
	syncExperitestWithUser,
	updateUTMSource
];
