import React, { Component } from "react";

export default function asyncComponent(getComponent) {
	class AsyncComponent extends Component {
		static ResolvedComponent = null;
		state = { ResolvedComponent: AsyncComponent.ResolvedComponent };

		componentWillMount() {
			if (!this.state.Component) {
				getComponent().then(ResolvedComponent => {
					AsyncComponent.ResolvedComponent = ResolvedComponent;
					this.setState({ ResolvedComponent });
				});
			}
		}

		render() {
			const { ResolvedComponent } = this.state;
			if (ResolvedComponent) {
				return <ResolvedComponent {...this.props} />;
			}
			return null;
		}
	}

	return AsyncComponent;
}
