import styled from "styled-components";
import LaddaButton from "react-ladda";
import {Button as _Button} from "reactstrap";
import PropTypes from "prop-types";

export const Separator = styled.div`
    width: 100%;
    height: ${props => props.height || 2 }px;
    background-color: #eee;
    margin-top: ${props => props.spacing || 0 }px;
    margin-bottom: ${props => props.spacing || 0 }px;
`;

export const ContentContainer = styled.div`
    width: 800px;
    max-width: 100%;
    margin: auto;
    padding: 0 20px;
    flex: 1;
    
    & h1 {
        font-size: 26px;
        font-weight: 600;
        margin-bottom: 40px;
    }
    
    & h3 {
        margin: 8px 0;
        font-size: 23px;
        font-weight: 600;
    }
    
    & p {
        margin-left: 0;    
    }
`;

export const PageContainer = styled.div`
    display: flex;
    min-height: 100vh;
    flex-direction: ${props => props.row ? "row" : "column"};
`;


export const FormWrapper = styled.div`

    padding: 30px 0;
    
    & label {
        padding-left: 0;
        font-weight: 500;
        font-size: 15px;
        margin-bottom: 10px;
    }
    
`;


export let SubmitButton;

if (process.env.NODE_ENV === "test") {

    SubmitButton = styled(_Button)`
        width: ${props => props.width ? props.width + "px" : "initial" };
        background-color: orange;
        padding: 8px;
        border-radius: 24px;
        font-size: 15px;
        font-weight: 600;
    `;

} else {

    SubmitButton = styled(LaddaButton)`    
        width: ${props => props.width ? props.width + "px" : "initial" };
        background-color: orange;
        padding: 8px;
        border-radius: 24px;
        font-size: 15px;
        font-weight: 600;
    `;
}

SubmitButton.propTypes = {
    loading: PropTypes.oneOf([0, 1]),
};


export const ProfilePictureRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 30px 0;
`;

export const Section = styled.div`

    display: flex;
    flex-direction: row;
    
    padding: 30px 10px;
    
    & > div {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: space-around;
    }
    
    & > div:nth-child(2) {
        align-items: flex-end;
    }
    
    & h1 {
        color: black;
    }
    
    & p {
        color: grey;
    }

`;

export const Button = styled(_Button)`
  
  background: ${props => props.primary ? 'orange' : 'white'};
  color: ${props => props.primary ? 'white' : 'orange'};
  
  cursor: pointer;
  
  width: ${props => props.width ? props.width + "px" : "initial" };
  
  border: 2px solid orange;
  border-radius: 20px;
  
  height: ${ props => props.circle ? props.width + "px" : 'initial' };
  
`;

export const PageLayover = styled.div`
  
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 11111;
  
  display: ${props => props.active ? 'block' : 'none' };
  
`;
