import React, { Component } from "react";
import PropTypes from "prop-types";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import ReactPhoneInput from "react-phone-input-2";
import { Alert, Button, Col, Row } from "reactstrap";
import { EXPAND_LEFT } from "react-ladda";
import { SubmitButton } from "./styled-components";

export default class SmsPhoneForm extends Component {
	static propTypes = {
		onValidSubmit: PropTypes.func.isRequired,
		onCancelation: PropTypes.func.isRequired,
		error: PropTypes.string,
		loading: PropTypes.bool
	};

	static defaultProps = {
		error: null,
		loading: false
	};

	state = {};


	handleCancelation = () => {
		this.props.onCancelation();
	};

	handleValidSubmit = (event, values) => {
		const phoneNumber = this.state.phoneNumber;
		const dialCode = this.state.dialCode;
		this.props.onValidSubmit(phoneNumber, dialCode);
	};

	handlePhoneOnChange = (value, infoObj) => {

		// TODO verification is needed
		this.setState({
			country: infoObj.countryCode,
			dialCode: infoObj.dialCode,
			phoneNumber: `+${value.replace(/\D/g, "")}`
		});
	};

	render() {
		return (
			<AvForm className="text-left" onValidSubmit={this.handleValidSubmit}>
				<Row>
					<Col>
						<label>Please enter your phone number</label>
						<ReactPhoneInput defaultCountry="us"
										 value={this.state.phoneNumber}
										 onChange={this.handlePhoneOnChange}/>
					</Col>
				</Row>
				{this.props.error && <Alert color="danger">{this.props.error}</Alert>}
				<br/>
				<AvGroup className="text-left">
					<SubmitButton
						width={150}
						loading={this.props.loading ? 1 : 0}
						data-color="#eee"
						data-style={EXPAND_LEFT}
						data-spinner-color="#ddd">
						Continue
					</SubmitButton>
					<Button type="button" color="default" className="cancel-btn"
							onClick={this.handleCancelation}>Cancel</Button>
				</AvGroup>
			</AvForm>
		);
	}
}
