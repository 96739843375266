import React, { Component } from "react";
import LayoutMessage from "./layout-message";

class ErrorBoundary extends Component {

	state = { hasError: false };

	componentDidCatch(error, info) {

		// send error stack to server
		console.error(info.componentStack);

		this.setState({ hasError: true });
	}

	render() {
		if (this.state.hasError) {
			return (
				<LayoutMessage>
					<h1>Something went wrong.</h1>
				</LayoutMessage>
			);
		}
		return this.props.children;
	}
}

export default ErrorBoundary;
