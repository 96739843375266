import { DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import styled from "styled-components";

import media from "../utils/style-utils";
import { Button } from "./styled-components";

export const MenuCollapseButton = styled(DropdownToggle)`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: ${props => props.dark ? "#ddd" : "#777"};
    cursor: pointer;
    
    &:hover {        
        color: ${props => props.dark ? "#fff" : "#333"};
    }
`;

export const FullName = styled.h6`
  margin: 0;
  
  ${media.lg`display: none;`}
`;

export const Menu = styled(DropdownMenu)`
    color: black;
    margin-top: 10px;
    min-width: 300px;
    padding: 15px;
    border-radius: 1px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    
  & img {
    margin-right: 20px;
  }
  
`;

export const MenuItem = styled(DropdownItem)`
    font-weight: 300;
    color: black;
    text-decoration: none;
    padding: 8px 16px;
    font-size: 15px;
    
    &:active {
        background-color: #eee;
        color: black;
    }
`;

export const ViewProfileButton = styled(Button)`
    font-size: 11px;
    padding: 5px 20px;
    border-radius: 1px;   
`;
