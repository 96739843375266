export const SEND_PHONE_NUMBER_PENDING = "[SMS] SEND_PHONE_NUMBER_PENDING";

export const SEND_PHONE_NUMBER_FULFILLED = "[SMS] SEND_PHONE_NUMBER_FULFILLED";

export const SEND_PHONE_NUMBER_REJECTED = "[SMS] SEND_PHONE_NUMBER_REJECTED";

export const sendPhoneNumber = (phoneNumber, dialCode) => ({
	type: SEND_PHONE_NUMBER_PENDING,
	payload: {
		phoneNumber,
		dialCode
	}
});

export const SEND_SMS_CODE_PENDING = "[SMS] SEND_SMS_CODE_PENDING";

export const SEND_SMS_CODE_FULFILLED = "[SMS] SEND_SMS_CODE_FULFILLED";


export const sendSmsCode = (phoneNumber, dialCode, smsCode) => ({
	type: SEND_SMS_CODE_PENDING,
	payload: {
		phoneNumber,
		dialCode,
		smsCode
	}
});

export const SAVE_USER_PHONE_PENDING = "[SMS] SAVE_USER_PHONE_PENDING";

export const sendSavePhone = (phoneNumber, dialCode) => ({
	type: SAVE_USER_PHONE_PENDING,
	payload: {
		phoneNumber,
		dialCode
	}
});

export const SAVE_USER_PHONE_FULFILLED = "[SMS] SAVE_USER_PHONE_FULFILLED";

export const SAVE_USER_PHONE_REJECTED = "[SMS] SAVE_USER_PHONE_REJECTED";

export const SEND_SMS_CODE_REJECTED = "[SMS] SEND_SMS_CODE_REJECTED";


export const ID_REAUTH_PENDING = "[SMS] ID_REAUTH_PENDING";

export const ID_REAUTH_FULFILLED = "[SMS] ID_REAUTH_FULFILLED";

export const ID_REAUTH_REJECTED = "[SMS] ID_REAUTH_REJECTED";

export const failedToReauthId = (error) => ({
	type: ID_REAUTH_REJECTED,
	payload: error
});
