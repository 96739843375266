import React from "react";
import styled from "styled-components";

import UncontrolledModal from "./uncontrolled-modal";
import ModalDeleteInvitation from "./modal-delete-invitation";
import { Button } from "./styled-components";
import { Icon } from "./icon";

const List = styled.div`
  display: flex;
  flex-direction: column;
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0;
`;

const InvitationList = ({ invitations, updateMyTeam }) => (
	<List>
		{
			invitations.map(email =>
				<ListItem key={email}>
					<div>
						<UncontrolledModal modalComponent={ModalDeleteInvitation} email={email} onClose={updateMyTeam}>
							<Button primary className={"mr-3 p-0"} circle width={30}>
								<Icon name={"times"}/>
							</Button>
						</UncontrolledModal>
					</div>

					<div>{email}</div>

				</ListItem>
			)
		}
	</List>
);

export default InvitationList;
