import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { MyTeamActions } from "../utils/my-team";

class ConfirmInvitationPrompt extends Component {

	static propTypes = {
		invitation: PropTypes.shape({ inviterUserEmail: PropTypes.string.isRequired }).isRequired,
		linkId: PropTypes.string.isRequired,
		auth: PropTypes.object.isRequired
	};

	state = {
		loading: false,
		sent: false,
		message: ""
	};

	reject = () => {

		this.setState({ loading: true });

		MyTeamActions.rejectInvitation(this.props.linkId)
			.then(() => this.setState({
				loading: false,
				sent: true,
				message: "invitation is rejected successfully"
			}))
			.catch((err) => {

				console.log(err);

				this.setState({
					loading: false,
					sent: true,
					message: JSON.stringify(err.message)
				});
			});
	};

	confirm = () => {

		this.setState({ loading: true });

		MyTeamActions.confirmInvitation(this.props.linkId)
			.then(() => this.setState({ loading: false, success: true, sent: true }))
			.then(() => this.props.auth.renewToken())
			.then(() => this.props.navigate("/my-team"))
			.catch((err) => {

				console.log(err);

				this.setState({
					loading: false,
					sent: true,
					message: JSON.stringify(err.message)
				});
			});
	};

	render() {

		return (
			<div>

				<h1>
					Confirm Project Invitation
				</h1>

				{this.state.loading && <h2>loading</h2>}

				{!this.state.loading && (
					<div>

						<p>
							You are invited to join seetest.io project by {this.props.invitation.inviterUserEmail}.
						</p>
						{!this.state.sent && (
						<p>
							Do you accept the invitation?
						</p>
						)}

						{this.state.success && (
							<h2>success!!</h2>
						)}

						{!this.state.message && (
							<h2>{this.state.message}</h2>
						)}

						{!this.state.sent && (<div>
							<Button disabled={this.state.loading} onClick={this.reject}>reject</Button>

							<Button disabled={this.state.loading} onClick={this.confirm}>confirm</Button>
						</div>)}

					</div>
				)}

			</div>
		);
	}
}


const mapStateToProps = (state, ownProps) => ({
	auth: state.authentication.auth
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	navigate: (url) => dispatch(push(url))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ConfirmInvitationPrompt);
