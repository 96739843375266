
export const GET_TEAM_PENDING = "[TEAM] GET_TEAM_PENDING";

export const getMyTeam = (ignoreCache = false) => ({
	type: GET_TEAM_PENDING,
	meta: {
		ignoreCache
	}
});

export const GET_TEAM_FULFILLED = "[TEAM] GET_TEAM_FULFILLED";

export const GET_TEAM_REJECTED = "[TEAM] GET_TEAM_REJECTED";

export const GET_TEAM_CANCELLED = "[TEAM] GET_TEAM_CANCELLED";
