import React, { Component, Fragment } from "react";

import { connect } from "react-redux";
import { show } from "redux-modal";

import { Button, Section } from "../components/styled-components";
import { loadMyProfile, updateMyProfile } from "../redux/actions/my-profile";
import { getFormError, getFormMessage } from "../redux/reducers/forms";
import LayoutProfilePage from "../components/layout-profile-page";
import ModalDeleteMyAccount from "../components/modal-delete-my-account";


export class ProfilePreferences extends Component {

	static displayName = "ProfilePreferences";

	componentDidMount() {
		this.props.loadMyProfile();
	}

	handleValidSubmit(event, { firstName, lastName }) {
		this.props.updateProfile(firstName, lastName);
	}

	render() {

		return (
			<LayoutProfilePage>

				<Fragment>


					<Section>

						<div>
							<h3>
								Delete My Account
							</h3>
						</div>

						<div>
							<Button primary={false} width={150}
									onClick={() => this.props.openModal("deleteMyAccount")}>Delete</Button>
							<ModalDeleteMyAccount/>
						</div>

					</Section>


				</Fragment>
			</LayoutProfilePage>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.myProfile.content,
	idToken: state.authentication.idToken,
	isSubmitting: state.ui.loading,
	submitMessage: getFormMessage(state.forms, "userProfile"),
	isError: getFormError(state.forms, "userProfile")
});

const mapDispatchToProps = (dispatch) => ({
	loadMyProfile: () => dispatch(loadMyProfile()),
	updateProfile: (firstname, lastname) => dispatch(updateMyProfile(firstname, lastname)),
	openModal: (name, props) => dispatch(show(name, props))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ProfilePreferences);
