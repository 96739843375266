
import axios from "axios";

export function getPaymentFieldsTokens() {

	return axios.post(`/api/billing/get-payment-fields-tokens`)
		.then(res => res.data.token);
}

export async function submitCheckoutForm(planName, formData, cardData, pfToken) {

    const { firstName, lastName, country, zip } = formData;
    const { ccType, last4Digits, exp, issuingCountry } = cardData;

    const body = JSON.stringify({
        planName,
        firstName,
        lastName,
        ccType,
        last4Digits,
        exp,
        issuingCountry,
        country,
        zip,
        pfToken
    });

    const res = await fetch(`/api/billing/submit-checkout-form`, {
        method: "POST",
        credentials: 'same-origin',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        },
        body
    });

    if (res.ok) return;

    const error = await res.json();

    throw new Error(error.message);
}

export function cancelSubscription() {

	return axios.post(`/api/billing/cancel-subscription`)
}

export function sendBlueSnapForm(bluesnap) {

    return new Promise((resolve, reject) => {

        if (!bluesnap) {
            reject("bluesnap is not found on window object");
        }

        else {
            bluesnap.submitCredentials((cardData) => {

                if (!cardData.ccType || !cardData.last4Digits || !cardData.exp) {
                    reject("missing field");
                }

                resolve(cardData);
            });

            setTimeout(() => {
                reject("Timeout is reached");
            }, 10 * 1000);
        }
    });
}

const plans = {
	starter: {
		monthly: {
			name: "Starter",
			cycleCharge: "29$",
			monthlyCharge: "29$"
		},
		yearly: {
			name: "Starter",
			cycleCharge: "299$",
			monthlyCharge: "25$"
		}
	},
	developer: {
		monthly: {
			name: "Developer",
			cycleCharge: "99$",
			monthlyCharge: "99$"
		},
		yearly: {
			name: "Developer",
			cycleCharge: "999$",
			monthlyCharge: "85$"
		}
	},
	team: {
		monthly: {
			name: "Team",
			cycleCharge: "199$",
			monthlyCharge: "199$"
		},
		yearly: {
			name: "Team",
			cycleCharge: "1999$",
			monthlyCharge: "165$"
		}
	},
};

export function getPlanByPlanName(name, period) {

	return plans[name.toLowerCase()][period.toLowerCase()];
}
