
export const UPDATE_PROFILE_REQUEST = "[PROFILE] UPDATE_PROFILE_PENDING";

export const updateMyProfile = (firstname, lastname) => ({
	type: UPDATE_PROFILE_REQUEST,
	payload: {
		firstname,
		lastname
	}
});

export const UPDATE_PROFILE_FULFILLED = "[PROFILE] UPDATE_PROFILE_FULFILLED";

export const UPDATE_PROFILE_REJECTED = "[PROFILE] UPDATE_PROFILE_REJECTED";

//

export const GET_PROFILE_PENDING = "[PROFILE] GET_PROFILE_PENDING";

export const loadMyProfile = (ignoreCache = false) => ({
	type: GET_PROFILE_PENDING,
	meta: {
		ignoreCache
	}
});

export const GET_PROFILE_FULFILLED = "[PROFILE] GET_PROFILE_FULFILLED";

export const GET_PROFILE_REJECTED = "[PROFILE] GET_PROFILE_REJECTED";

export const GET_PROFILE_CANCELLED = "[PROFILE] GET_PROFILE_CANCELLED";

//

export const DELETE_MY_ACCOUNT_OPEN_DIALOG = "[DELETE_MY_ACCOUNT] OPEN DIALOG";

export const openDeleteMyAccountModal = () => ({
	type: DELETE_MY_ACCOUNT_OPEN_DIALOG
});

export const DELETE_MY_ACCOUNT_REQUEST = "[DELETE_MY_ACCOUNT] REQUEST";

export const deleteMyAccountRequest = (email) => ({
	type: DELETE_MY_ACCOUNT_REQUEST,
	payload: { email }
});

export const DELETE_MY_ACCOUNT_FULFILLED = "[DELETE_MY_ACCOUNT] DELETE_MY_ACCOUNT_FULFILLED";

export const DELETE_MY_ACCOUNT_REJECTED = "[DELETE_MY_ACCOUNT] DELETE_MY_ACCOUNT_REJECTED";
