
export const CLOUD_USAGE_REQUEST = `[CLOUD_USAGE] REQUEST`;

export const fetchCloudUsage = () => ({
	type: CLOUD_USAGE_REQUEST
});

export const CLOUD_USAGE_FULFILLED = `[CLOUD_USAGE] FULFILLED`;

export const CLOUD_USAGE_REJECTED = `[CLOUD_USAGE] REJECTED`;
