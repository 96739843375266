
import { CLOUD_USAGE_REQUEST, CLOUD_USAGE_FULFILLED, CLOUD_USAGE_REJECTED } from "../actions/cloud-usage";
import { RESET_REQUEST } from "../actions/authentication";

const initialState = {
	fetching: false,
	content: []
};

const reducer = (state = initialState, action) => {

	switch (action.type) {

		case CLOUD_USAGE_REQUEST:
			return {
				...state,
				error: null,
				fetching: true
			};

		case CLOUD_USAGE_FULFILLED:
			return {
				...state,
				error: null,
				content: action.payload,
				fetching: false
			};

		case CLOUD_USAGE_REJECTED:
			return {
				...state,
				error: action.payload,
				fetching: false
			};

		case RESET_REQUEST:
			return initialState;

		default:
			return state;
	}
};

export default reducer;
