
import ReactGA from "react-ga";
import {REACT_APP_INTERCOM_APP_ID, REACT_APP_GA_TRACKING_CODE} from "./environment";

export const initGA = GA_TRACKING_CODE => {

    if (process.env.NODE_ENV === "test") {
        console.log("skip init in test env");
    }

    else {
        console.log("GA init");
        ReactGA.initialize(GA_TRACKING_CODE);
    }
};

export const logPageView = () => {
    console.log(`Logging pageview for ${window.location.pathname}`);
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
};

export const registerHistory = (history) => {

    if (window.Intercom) {
        window.Intercom("boot", {
            app_id: REACT_APP_INTERCOM_APP_ID
        });
    }

    initGA(REACT_APP_GA_TRACKING_CODE);
    history.listen(logPageView);
};
