import { combineReducers } from "redux";
import { reducer as modal } from "redux-modal";

import myTeam from "./my-team";
import myProfile from "./my-profile";
import authentication from "./authentication";
import login from "./login";
import deleteMyAccount from "./delete-my-account";
import ui from "./ui";
import forms from "./forms";
import paymentHistory from "./payment-history";
import teamSubscription from "./team-subscription";
import cloudUsage from "./cloud-usage";
import smsVerification from "./sms-verification";

const rootReducer = combineReducers({

	// logic
	myTeam,
	teamSubscription,
	myProfile,
	authentication,
	login,
	paymentHistory,
	deleteMyAccount,
	cloudUsage,
	smsVerification,

	// ui
	ui,
	forms,

	// plugins
	modal,
});

export default rootReducer;
