import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Col, FormGroup, Row } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { connect } from "react-redux";

import LoginLayout from "../../components/layout-login";
import SmallHomeLink from "../../components/small-home-link";

class ForgotPassword extends Component {

	state = {
		result: "",
		sending: false
	};

	handleValidSubmit(event, values) {
		this.resetPassword(values.email);
	}

	resetPassword(email) {
		this.setState({
			result: "",
			sending: true
		});

		this.props.auth.requestPasswordReset(email)
			.then(result => this.setState({ result }))
			.catch(err => this.setState({ result: err }))
			.finally(() => this.setState({ sending: false }));
	}

	render() {
		return (
			<LoginLayout>

				<Row>
					<Col className="d-flex justify-content-between align-items-center">
						<div className="m-3">
							<div className="show-md text-left">
								<SmallHomeLink/>
							</div>
						</div>

						<p className="text-right text-help m-3">
							<span className={"hide-sm"}>
								Don't have an account?
							</span>
							<Link to="/signup" className="btn btn-primary ml-3 text-help">
								Signup
							</Link>
						</p>
					</Col>
				</Row>

				<Row>
					<div className="content-section">
						<h2 className="text-left">Forgot your password?</h2>
						<p className="lead text-left mb-5">
							Enter your email address below and we'll get you back on track.
						</p>

						{this.state.result === "" ? (
							<AvForm
								className={"text-left"}
								onValidSubmit={(event, values) => this.handleValidSubmit(event, values)}>
								<AvField
									name="email"
									autofocus="true"
									label="EMAIL ADDRESS"
									placeholder={"your@email.com"}
									type="email"
									required
								/>
								<FormGroup className={"text-center"}>
									<Button disabled={this.state.sending} color="primary">
										RESET MY PASSWORD
									</Button>
								</FormGroup>
							</AvForm>
						) : (
							<h5 className={"text-left"}>{this.state.result}</h5>
						)}
					</div>
				</Row>

				<Row>
					<Col>
						<div className="text-center text-muted">
							<p className="by-click">
								By clicking, you confirm that you agree to our{" "}
								<a rel="noreferrer noopener" target="_blank" href="https://seetest.io/legal">Terms
									of Use.</a>
							</p>
						</div>
					</Col>
				</Row>

			</LoginLayout>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	auth: state.authentication.auth
});

export default connect(
	mapStateToProps
)(ForgotPassword);
