import { GET_TEAM_CANCELLED, GET_TEAM_FULFILLED, GET_TEAM_PENDING, GET_TEAM_REJECTED } from "../actions/my-team";
import { apiRequest, ignoreApi } from "../actions/api";


const getMyTeamFlow = ({ dispatch, getState }) => next => action => {

	next(action);

	if (action.type === GET_TEAM_PENDING) {

		let shouldUpdate = false;

		if (getState().myTeam.content === null) {
			shouldUpdate = true;
		}

		if (action.meta && action.meta.ignoreCache) {
			shouldUpdate = true;
		}

		if (shouldUpdate) {
			dispatch(apiRequest("GET", "/api/teams/", null, GET_TEAM_FULFILLED, GET_TEAM_REJECTED));
		} else {
			dispatch(ignoreApi("GET", "/api/teams/"));
			dispatch({ type: GET_TEAM_CANCELLED })
		}
	}

};

export default [getMyTeamFlow];
