import { GET_TEAM_PENDING, GET_TEAM_FULFILLED, GET_TEAM_REJECTED, GET_TEAM_CANCELLED } from "../actions/my-team";
import { RESET_REQUEST } from "../actions/authentication";

const initialState = {
	isLoading: false,
	content: null,
	error: null
};

const myTeamReducer = (state = initialState, action) => {

	switch (action.type) {

		case GET_TEAM_PENDING:
			return {
				...state,
				isLoading: true
			};

		case GET_TEAM_FULFILLED:
			return {
				...state,
				content: action.payload,
				isLoading: false
			};

		case GET_TEAM_REJECTED:
			return {
				...state,
				error: action.payload,
				isLoading: false
			};

		case GET_TEAM_CANCELLED:
			return {
				...state,
				isLoading: false
			};

		case RESET_REQUEST:
			return initialState;

		default:
			return state;
	}
};

export default myTeamReducer;
