import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import asyncComponent from "../components/async-component";
import LayoutBillingPage from "../components/layout-billing-page";
import { Section } from "../components/styled-components";
import { fetchCloudUsage } from "../redux/actions/cloud-usage";

const CloudUsageTable = asyncComponent(() =>
	import("../components/cloud-usage-table").then(module => module.default)
);

class CloudUsagePage extends Component {

	componentDidMount() {
		this.props.loadCloudUsage();
	}

	render() {

		return (
			<LayoutBillingPage>

				<Fragment>

					<div>
						<Section>

							<h2>Cloud Usage</h2>

						</Section>

					</div>

					<div>

						<Section>

							<CloudUsageTable
								isLoading={this.props.isLoading}
								data={this.props.data}
							/>

						</Section>

					</div>

				</Fragment>
			</LayoutBillingPage>
		);
	}

}

const mapStateToProps = (state) => ({
	isLoading: state.cloudUsage.fetching,
	data: state.cloudUsage.content || []
});

const mapDispatchToProps = (dispatch) => ({
	loadCloudUsage: () => dispatch(fetchCloudUsage())
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CloudUsagePage);

