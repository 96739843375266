import { FORM_RESET_MESSAGE, FORM_SET_MESSAGE } from "../actions/forms";

const initialState = {};

const reducer = (state = initialState, action) => {

	switch (action.type) {

		case FORM_RESET_MESSAGE:
			return {
				...state,
				[action.payload]: null
			};

		case FORM_SET_MESSAGE: {
			const { formName, isError, message } = action.payload;
			return {
				...state,
				[formName]: {
					message,
					isError
				}
			};
		}

		default:
			return state;
	}
};

export const getFormError = (state, formName) => {
	return (state[formName] && state[formName].isError) || false;
};

export const getFormMessage = (state, formName) => {
	return (state[formName] && state[formName].message) || null;
};

export default reducer;
