import React from "react";

import styled from "styled-components";
import {Link} from "react-router-dom";

const Logo = styled.img`
	width: 105px;
`;

const SmallHomeLink = props => (
	<div>
		<Link to="/" className={props.className || ""}>
			<Logo src="/experitest-logo.png" alt="" />
		</Link>
	</div>
);

export default SmallHomeLink;
