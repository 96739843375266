import React, { Component } from "react";
import PropTypes from "prop-types";
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { AvFeedback, AvField, AvForm, AvGroup } from "availity-reactstrap-validation";
import { MyTeamActions } from "../utils/my-team";

class ModalInviteMember extends Component {

    static propTypes = {
        closeModal: PropTypes.func
    };

    state = {
        error: null
    };

    formRef = React.createRef();

	close = () => {
        this.props.closeModal();
    };

	handleValidSubmit = (event, values) => {

        this.setState({ error: null });

		MyTeamActions.inviteAnEmail(values.email)
            .then(() => {
                this.close();
            })
            .catch((err) => {
                console.log(err);
                this.setState({ error: err.response.data.message });
            });
    };

	submit = () => {
		this.formRef.current.submit();
	};

    render() {
        return (
            <Modal
                autoFocus={true}
                isOpen={this.props.isOpen}
                fade={true}
                toggle={this.close}
                backdrop={true}>
                <ModalHeader>Invite new member</ModalHeader>
                <ModalBody>
                    <AvForm ref={this.formRef} onValidSubmit={this.handleValidSubmit}>
                        <AvGroup>
                            <AvField
                                type="email"
                                name="email"
                                label="Enter an email"
                                required
                            />
                            <AvFeedback></AvFeedback>
                        </AvGroup>
                    </AvForm>
                    {this.state.error && <Alert color={"danger"}>{this.state.error}</Alert>}
                </ModalBody>

                <ModalFooter>
                    <Button color="secondary" onClick={this.close}>
                        Close
                    </Button>
					<Button color="primary" onClick={this.submit}>
						Invite
					</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default ModalInviteMember;
