
import {css} from 'styled-components'

const sizes = {
	xs: 0,
	sm: 480,
	md: 768,
	lg: 1024
};


/**
 *
 * @type {{
 *  xs: number,
 *  sm: number,
 *  md: number,
 *  lg: number
 * }}
 */
const media = Object.keys(sizes).reduce((accumulator, label) => {

    // use em in breakpoints to work properly cross-browser and support users
    // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
    const size = sizes[label];

    return Object.assign(accumulator, {
        [label]: (...args) => css`
            @media (max-width: ${size}px) {
              ${css(...args)}
            }
    `});

}, {});

export default media;

