import { apiRequest, ignoreApi } from "../actions/api";
import {
	GET_TEAM_SUB_PENDING,
	GET_TEAM_SUB_REJECTED,
	GET_TEAM_SUB_FULFILLED,
	GET_TEAM_SUB_CANCELLED
} from "../actions/team-subscription";


const getMyTeamFlow = ({ dispatch, getState }) => next => action => {

	next(action);

	if (action.type === GET_TEAM_SUB_PENDING) {

		let shouldUpdate = false;

		if (getState().teamSubscription.content === null) {
			shouldUpdate = true;
		}

		if (action.meta && action.meta.ignoreCache) {
			shouldUpdate = true;
		}

		if (shouldUpdate) {
			dispatch(apiRequest("GET", "/api/team-subscription/my", null, GET_TEAM_SUB_FULFILLED, GET_TEAM_SUB_REJECTED));
		} else {
			dispatch(ignoreApi("GET", "/api/team-subscription/my"));
			dispatch({ type: GET_TEAM_SUB_CANCELLED });
		}
	}

};

export default [getMyTeamFlow];
