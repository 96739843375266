import axios from "axios";
import { API_REQUEST } from "../actions/api";

function responseWrapperToError({ response }) {

	return {
		statusCode: response.status,
		statusText: response.statusText,
		message: response.data.message,
		errors: response.data.errors
	};
}

const api = ({ dispatch }) => next => action => {

	if (process.env.NODE_ENV === "test") {
		return next(action);
	}

	if (action.type === API_REQUEST) {

		const idToken = localStorage.getItem("id_token");

		const headers = idToken ? {
			authorization: `Bearer ${ idToken }`
		} : {};

		// TODO: remove after convert to redux
		axios.defaults.headers = headers;

		const { method, url, onSuccess, onFailure } = action.meta;

		axios
			.request({
				url,
				method,
				data: action.payload,
				headers
			})
			.then(response => dispatch({ type: onSuccess, payload: response.data }))
			.catch(responseWrapper => dispatch({ type: onFailure, payload: responseWrapperToError(responseWrapper) }));

	}

	return next(action);
};

export default [api];
