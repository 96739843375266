import { REACT_APP_EXPERITEST_BASE_URL } from "../environment";

async function processIframe(url) {

	// add iframe
	const iframeElement = document.createElement("iframe");
	iframeElement.src = url;
	iframeElement.style.display = "none";

	// wait for load
	await new Promise((resolve, reject) => {

		const messageListener = (event) => {
			if (event.origin === REACT_APP_EXPERITEST_BASE_URL) {
				window.removeEventListener("message", messageListener);
				setTimeout(() => {
					resolve();
				}, 600);
			}
		};

		window.addEventListener("message", messageListener);

		setTimeout(() => {
			window.removeEventListener("message", messageListener);
			reject("timeout");
		}, 3 * 1000);

		// append to document
		document.body.appendChild(iframeElement);
	})
		.then(() => {
			document.body.removeChild(iframeElement);
		})
		.catch(() => {
			document.body.removeChild(iframeElement);
		});

}

export function postLogin() {
	console.log("start postLogin");
	const idToken = localStorage.getItem("id_token");
	const setCookieUrl = `${REACT_APP_EXPERITEST_BASE_URL}/wp-content/themes/experitest/api/server-cookie.php`;
	const url = `${setCookieUrl}?idToken=${idToken}`;
	console.log("open iframe postLogin");
	return processIframe(url).then(_ => {
		console.log("closed iframe postLogin");
	});
}

export function postLogout() {
	console.log("start postLogout");
	const setLogoutUrl = `${REACT_APP_EXPERITEST_BASE_URL}/wp-content/themes/experitest/api/server-cookie.php`;
	return processIframe(setLogoutUrl).then(_ => {
		console.log("closed iframe postLogout");
	});
}

