
export const PAYMENT_HISTORY_REQUEST = `[PAYMENT_HISTORY] REQUEST`;

export const fetchPaymentHistory = () => ({
	type: PAYMENT_HISTORY_REQUEST
});

export const PAYMENT_HISTORY_FULFILLED = `[PAYMENT_HISTORY] FULFILLED`;

export const PAYMENT_HISTORY_REJECTED = `[PAYMENT_HISTORY] REJECTED`;
