import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

class UncontrolledModal extends Component {

	static propTypes = {
		modalComponent: PropTypes.func.isRequired,
		onClose: PropTypes.func
	};

	static defaultProps = {
		onClose: () => {
		}
	};

	state = {
		modalIsOpen: false
	};

	closeModal = () => {
		this.setState({ modalIsOpen: false });
		this.props.onClose();
	};

	showModal = () => {
		this.setState({ modalIsOpen: true });
	};

	render() {

		if (process.env.NODE_ENV === "test") {
			return <div></div>;
		}

		const ModalClass = this.props.modalComponent;

		return (
			<Fragment>
				<div onClick={this.showModal}>{this.props.children}</div>
				<div>
					<ModalClass {...this.props} isOpen={this.state.modalIsOpen} closeModal={this.closeModal}/>
				</div>
			</Fragment>
		);
	}
}

export default UncontrolledModal;
