
// site
const REACT_APP_GA_TRACKING_CODE = process.env.REACT_APP_GA_TRACKING_CODE;
const REACT_APP_GTAG_TRACKING_CODE = process.env.REACT_APP_GTAG_TRACKING_CODE;
const REACT_APP_INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;

// bluesnap
const REACT_APP_BLUESNAP_DOMAIN = process.env.REACT_APP_BLUESNAP_DOMAIN;

// main site url
const REACT_APP_MAIN_SITE_URL = process.env.REACT_APP_MAIN_SITE_URL;

// cloud site url
const REACT_APP_CLOUD_URL = process.env.REACT_APP_CLOUD_URL;

// cookies subdomain
const REACT_APP_COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN;

// auth0 login settings
const REACT_APP_AUTH0_CLIENTID = process.env.REACT_APP_AUTH0_CLIENTID;
const REACT_APP_AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
const REACT_APP_AUTH0_CLAIN_NAMESPACE = process.env.REACT_APP_AUTH0_CLAIN_NAMESPACE;

// experitest site
const REACT_APP_EXPERITEST_BASE_URL = process.env.REACT_APP_EXPERITEST_BASE_URL;

module.exports = {
    REACT_APP_GA_TRACKING_CODE,
    REACT_APP_GTAG_TRACKING_CODE,
    REACT_APP_INTERCOM_APP_ID,
    REACT_APP_BLUESNAP_DOMAIN,
    REACT_APP_MAIN_SITE_URL,
    REACT_APP_CLOUD_URL,
    REACT_APP_AUTH0_CLIENTID,
    REACT_APP_AUTH0_DOMAIN,
    REACT_APP_AUTH0_CLAIN_NAMESPACE,
    REACT_APP_COOKIE_DOMAIN,
	REACT_APP_EXPERITEST_BASE_URL
};
