import React from "react";
import PropTypes from "prop-types";

import styled from 'styled-components';

const Image = styled.img`
    border-radius: ${props => props.round ? "50%" : "1px"};
    width: ${props => props.size}px;
	height: ${props => props.size}px;
	margin: 5px 10px;
	box-shadow: ${props => props.frame ? "0 0 0px 0px #000000a3, 0 0 0px 2px white, 0 0 1px 3px #00000075" : "none"};
    display: inline-block;
`;

const PicturePlaceholder = styled.div`
    border-radius: ${props => props.round ? "50%" : "1px"};
    width: ${props => props.size}px;
	height: ${props => props.size}px;
	margin: 5px 10px;
	box-shadow: ${props => props.frame ? "0 0 0 2px white, 0 0 5px 1px #00000075" : "none"};
	background-color: white;
    display: inline-block;
`;

const UserAvatar = props => (
    <div>
        {props.picture ?
            <Image src={props.picture} alt={"user profile"} {...props} /> :
            <PicturePlaceholder {...props} />
        }
    </div>
);

UserAvatar.defaultProps = {
    picture: null,
    size: 100,
    round: true,
    frame: true
};

UserAvatar.propTypes = {
    picture: PropTypes.string.isRequired,
    size: PropTypes.number,
    round: PropTypes.bool,
    frame: PropTypes.bool
};

export default UserAvatar;
