import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import thunkMiddleware from "redux-thunk";
import loggerMiddleware from "redux-logger";
import rootMiddleware from "../middlewares";
import rootReducer from "../reducers";

const history = createBrowserHistory();

const composeEnhancer = process.env.NODE_ENV === "production" ?
	compose :
	(window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose);

const middlewares = [
	...(["production", "test"].includes(process.env.NODE_ENV) ? [] : [loggerMiddleware]),
	routerMiddleware(history),
	thunkMiddleware,
	...rootMiddleware
];

const createInitialStore = (initialState) => {
	return createStore(
		connectRouter(history)(rootReducer),
		initialState,
		composeEnhancer(
			applyMiddleware(
				...middlewares
			)
		)
	);
};

const store = createInitialStore();

export { store, history, createInitialStore };
