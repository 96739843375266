import React, { Component } from "react";
import styled from "styled-components";

import LayoutMessage from "../components/layout-message";

const Title = styled.h2`
	margin: 50px 0;
`;

class DeleteAccountSuccess extends Component {

	render() {
		return (
			<LayoutMessage>

				<p>
					<center>
						<img src="logo.png" alt="seetest io" height="186" width="124"/>
					</center>
				</p>

				<Title>
					Your account has been archived successfully
				</Title>

				<p>
					We are sorry to see you go.
				</p>

				<p>
					Your account will be archived for the next 14 days.
				</p>

				<p>
					During this period you can login back in any time and you profile will be retrieved.
				</p>

				<p>
					After the 14 days period we will wipe all you data and you will not be
					able to retrieve your account.
				</p>
			</LayoutMessage>
		);
	}
}

export default DeleteAccountSuccess;
