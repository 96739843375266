import React, { Component, Fragment } from "react";
import { Alert, FormGroup } from "reactstrap";

import { EXPAND_LEFT } from "react-ladda";
import { AvFeedback, AvField, AvForm, AvGroup } from "availity-reactstrap-validation";
import { connect } from "react-redux";

import { ContentContainer, FormWrapper, SubmitButton } from "../components/styled-components";
import { NAME_PATTERN } from "../utils/constants";
import { loadMyProfile, updateMyProfile } from "../redux/actions/my-profile";
import LoadingSpinner from "../components/loading-spinner";
import { getFormError, getFormMessage } from "../redux/reducers/forms";
import LayoutProfilePage from "../components/layout-profile-page";


export class MyProfile extends Component {

	static displayName = "MyProfile";

	componentDidMount() {
		this.props.loadMyProfile();
	}

	handleValidSubmit(event, { firstName, lastName }) {
		this.props.updateProfile(firstName, lastName);
	}

	render() {

		const user = this.props.user;

		const defaultValues = {
			firstName: user && user.firstName,
			lastName: user && user.lastName,
			email: user && user.email
		};

		return (
			<LayoutProfilePage>

				<Fragment>

					{!user && <LoadingSpinner className={"p-5 text-center"}/>}

					{user && (

						<ContentContainer>

							<FormWrapper>

								<h1>My Profile</h1>

								<AvForm onValidSubmit={(event, values) => this.handleValidSubmit(event, values)}
										model={defaultValues}>

									<AvGroup>
										<AvField
											type="text"
											name="firstName"
											label="First Name"
											validate={{
												required: {
													value: true,
													errorMessage: "This field is required"
												},
												pattern: {
													value: NAME_PATTERN,
													errorMessage: "Only A-Z, a-z and spaces are valid chars"
												},
												minLength: {
													value: 2,
													errorMessage: "Name must contain at least 2 chars"
												}
											}}
										/>
										<AvFeedback>Please enter your name</AvFeedback>
									</AvGroup>

									<AvGroup>
										<AvField
											type="text"
											name="lastName"
											label="Last Name"
											validate={{
												required: {
													value: true,
													errorMessage: "This field is required"
												},
												pattern: {
													value: NAME_PATTERN,
													errorMessage: "Only A-Z, a-z and spaces are valid chars"
												},
												minLength: {
													value: 2,
													errorMessage: "Name must contain at least 2 chars"
												}
											}}
										/>
										<AvFeedback>Please enter your name</AvFeedback>
									</AvGroup>
									<AvGroup>
										<AvField type="text" name="email" label="Email" disabled/>
									</AvGroup>
									<FormGroup>
										<SubmitButton
											width={150}
											loading={this.props.isSubmitting ? 1 : 0}
											data-color="#eee"
											data-style={EXPAND_LEFT}
											data-spinner-color="#ddd">
											Update
										</SubmitButton>
									</FormGroup>
								</AvForm>

								{this.props.submitMessage && (
									<Alert color={this.props.isError ? "danger" : "success"}>
										{this.props.submitMessage}
									</Alert>
								)}

							</FormWrapper>

						</ContentContainer>

					)}

				</Fragment>
			</LayoutProfilePage>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.myProfile.content,
	idToken: state.authentication.idToken,
	isSubmitting: state.ui.loading,
	submitMessage: getFormMessage(state.forms, "userProfile"),
	isError: getFormError(state.forms, "userProfile")
});

const mapDispatchToProps = (dispatch) => ({
	loadMyProfile: () => dispatch(loadMyProfile()),
	updateProfile: (firstname, lastname) => dispatch(updateMyProfile(firstname, lastname))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MyProfile);
