import React from "react";
import styled from "styled-components";
import {Icon} from "./icon";
import media from "../utils/style-utils";

const CompanyButton = styled.button`

    cursor: pointer;
    outline: none;
    background-color: ${props => props.color};
    color: ${props => props.textColor} !important;
    padding: 12px;
    width: 100%;
    height: 100%;

    &:disabled {
      cursor: auto;
    }

    &:hover {
        background-color: ${props => props.hoverColor};
    }

    ${media.sm`
		.name {
			display: none;
		}
    `}
`;

const ButtonGenerator = (color, hoverColor, icon, title, textColor = "white") => props => (
    <div className={props.className || ""}>
        <CompanyButton className="btn" color={color} textColor={textColor} hoverColor={hoverColor} onClick={props.onClick} disabled={props.disabled}>
            <Icon name={icon} brand />
            <span className={"name"} >
                {props.preText || ""} {title}
            </span>
        </CompanyButton>
    </div>
);

export const GoogleButton = ButtonGenerator("#dd4b39", "#c23321", "google", "Google");

export const BitbucketButton = ButtonGenerator("#205081", "#23527c", "bitbucket", "Bitbucket");

export const GithubButton = ButtonGenerator("#444", "#333", "github", "Github");

export const LinkedinButton = ButtonGenerator("#205081", "#23527c", "linkedin", "LinkedIn");

export const GSuiteButton = ButtonGenerator("#eee", "#ddd", "google", "G Suite", "black");
