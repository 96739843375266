
import React, {Component} from "react";
import PropTypes from "prop-types";

class ExternalLink extends Component {

    static propTypes = {
        to: PropTypes.string.isRequired
    };

    componentDidMount() {

        const pendingRedirect = localStorage.getItem("pending_redirect");
        localStorage.removeItem("pending_redirect");

        window.location.replace(pendingRedirect || this.props.to);
    }

    render () {

        return (
            <div></div>
        )
    }
}

export default ExternalLink;
