module.exports = [
	"yahoo.co.uk",
	"0hdear.com",
	"0hiolce.com",
	"10minut.xyz",
	"163.com",
	"20mm.eu",
	";id",
	"Agshdu.com",
	"anom.com",
	"aol.com",
	"applause.com",
	"avilevi.com",
	"binka.me",
	"bk.ru",
	"borland.com",
	"checksdqd",
	"crazymail.guru",
	"deyom.com",
	"dxc",
	"emailnax.com",
	"endrix.org",
	"freemail.hu",
	"gbouquete.com",
	"getairmail.com",
	"getnada.com",
	"giyam.com",
	"gmail.com",
	"gmx.com",
	"gmx.es",
	"gmx.info",
	"gmx.net",
	"googlemail.com",
	"hideemail.net",
	"hotmail.co.uk",
	"hotmail.com",
	"hotmail.fr",
	"hotmail.se",
	"hp.com",
	"hpe.com",
	"huawei.com",
	"i.ua",
	"icloud.com",
	"inbox.ru",
	"inboxbear.com",
	"ipdeer.com",
	"live.com",
	"live.nl",
	"ln0rder.com",
	"mail.bg",
	"mail.com",
	"mail.ru",
	"mailforspam.com",
	"mailinator",
	"mailmetal.com",
	"me.com",
	"microfocus.com",
	"mobilelabsinc.com",
	"morsin.com",
	"mvrht.com",
	"mvrht.net",
	"my.com",
	"my10minutemail.com",
	"neotys.com",
	"nsogroup.com",
	"nu.edu.pk",
	"outlook.com",
	"perfecto.io",
	"perfectomobile.com",
	"perforce.com",
	"psoxs",
	"q.com",
	"qq.com",
	"rediffmail.com",
	"resmail24.com",
	"rocketmail",
	"rocketmail.com",
	"rockmail",
	"rockmail.com",
	"sapo.pt",
	"saucelabs.com",
	"sharklasers.com",
	"sjuaq.com",
	"smartbear.com",
	"soasta.com",
	"some.com",
	"spam4.me",
	"tbabs.co.uk",
	"testplant.com",
	"ua.fm",
	"verizon.net",
	"walla",
	"yahoo",
	"yahoo.ca",
	"yahoo.co.in",
	"yahoo.com",
	"yahoo.com.au",
	"yahoo.es",
	"yandex",
	"yandex.com",
	"yandex.ru",
	"yaooo.com",
	"yeah.net",
	"ymail.com",
	"yopmail",
	"yopmail.com"
]
