import {
	LOGIN_CONNECTION_PENDING,
	LOGIN_CREDENTIALS_PENDING,
	LOGIN_FULFILLED,
	LOGIN_REJECTED, SIGNUP_CREDENTIALS_PENDING
} from "../actions/authentication";

const initialValue = {
	loading: false,
	error: null,
};

const loginReducer = (state = initialValue, action) => {

	switch (action.type) {

		case SIGNUP_CREDENTIALS_PENDING:
		case LOGIN_CREDENTIALS_PENDING:
			return {
				...state,
				error: null,
				loading: true
			};

		case LOGIN_CONNECTION_PENDING:
			return {
				...state,
				error: null,
				loading: true
			};

		case LOGIN_FULFILLED:
			return {
				...state,
				loading: false
			};

		case LOGIN_REJECTED:
			return {
				...state,
				loading: false,
				error: action.payload
			};


		default:
			return state;
	}
};

export default loginReducer;
