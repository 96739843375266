import React from "react";
import { Badge } from "reactstrap";
import styled from "styled-components";

import UncontrolledModal from "./uncontrolled-modal";
import ModalRemoveMember from "./modal-remove-member";
import { Button } from "./styled-components";
import { Icon } from "./icon";

const List = styled.div`
  display: flex;
  flex-direction: column;
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0;
`;

const TeamMembersList = ({ members, updateMyTeam, currentUser }) => (
	<List>
		{
			[...members].sort((a, b) => {
				if (a.role !== b.role) {
					return a.role < b.role ? -1 : 1;
				}
				const lcA = a.email.toLowerCase();
				const lcB = b.email.toLowerCase();
				if (a.role === "admin") {
					const lcCurrentUser = currentUser.email.toLowerCase();
					if (lcA === lcCurrentUser) {
						return -1;
					} else if (lcB === lcCurrentUser) {
						return 1;
					}
				}
				if (lcA !== lcB) {
					return lcA < lcB ? -1 : 1;
				}
				return 0;
			}).map(item =>
				<ListItem key={item.email}>

					{currentUser.role === "admin" && currentUser.email !== item.email && (
						<div>
							<UncontrolledModal modalComponent={ModalRemoveMember}
											   email={item.email}
											   onClose={updateMyTeam}>
								<Button primary className={"mr-3 p-0"} circle width={30}>
									<Icon name={"times"}/>
								</Button>
							</UncontrolledModal>
						</div>
					)}

					<div>
						<span>{item.email}</span>
						<Badge pill className={"ml-2"}>{item.role}</Badge>
					</div>

				</ListItem>
			)
		}
	</List>
);

export default TeamMembersList;
