import React, { Component } from "react";
import {  AvForm } from "availity-reactstrap-validation";
import {  Button, Col,  Row } from "reactstrap";
import queryString from "querystring";
import { connect } from "react-redux";

import LoginLayout from "../../components/layout-login";

import SmallHomeLink from "../../components/small-home-link";
import { REACT_APP_AUTH0_DOMAIN } from "../../environment";
import Authentication from "../../utils/Auth";

class NoConfirmationAuthLogin extends Component {

	state = {
		firstname: "",
		lastname: "",
		formError: ""
	};

	componentWillMount() {
		const parsed = queryString.parse(window.location.search);

		const firstname = parsed["?firstname"] || parsed.firstname;
		const lastname = parsed["?lastname"] || parsed.lastname;

		this.setState({
			firstname,
			lastname
		});
	}

	handleValidSubmit = (event, values) => {
		const parsed = queryString.parse(window.location.search);

		const state = parsed["?state"] || parsed.state;
		const token = parsed["?token"] || parsed.token;

		const { payload } = Authentication.decodeIdToken(token);

		const { firstname, lastname } = values;

		this.props.auth.updateUserDetails(token, payload, firstname, lastname)
			.then(() => {
				window.location = `https://${REACT_APP_AUTH0_DOMAIN}/continue?token=${token}&state=${state}`;
			})
			.catch(err => {
				this.setState({
					...this.state,
					formError: err.description || JSON.stringify(err)
				});
			});
	};

	render() {

		const defaultValues = {
			firstname: this.state.firstname,
			lastname: this.state.lastname
		};

		return (
			<LoginLayout className="min-height">
				<Row>
					<Col className="d-flex justify-content-between align-items-center">
						<div className="m-3">
							<div className="show-md text-left">
								<SmallHomeLink/>
							</div>
						</div>
					</Col>
				</Row>

				<Row>
					<AvForm className="text-left"  model={defaultValues}>
					<div className="content-section">
						<h2 className="text-left">Unable to login</h2>
						<p className="lead text-left mb-5">
							Please click on the Signup button In order to continue registering for SeeTest,
							or add your username and password for login.
						</p>

						<Button  onClick={() =>window.close()} color="primary">Got it!</Button>
					</div>
					</AvForm>
				</Row>

				<Row/>

			</LoginLayout>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	auth: state.authentication.auth
});

export default connect(
	mapStateToProps
)(NoConfirmationAuthLogin);
