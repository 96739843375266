import React, { Component } from "react";
import { Link } from "react-router-dom";

import LayoutMessage from "../components/layout-message";

class NoMatch extends Component {

	static displayName = "NoMatch";

	render() {
		return (
			<LayoutMessage>
				<h1>Oops, Page not found</h1>
				<p>
					The page you arrived to doesn't exist.
				</p>
				<p>
					<Link to={"/"}>Click here</Link>{"  to return to the homepage"}
				</p>
			</LayoutMessage>
		);
	}
}

export default NoMatch;
